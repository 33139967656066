import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Provider28East = () => {
    const [provider28EastList, setProvider28EastList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [formData, setFormData] = useState({
        name_28east: '',
        status: 'active'
    });
    const [sortConfig, setSortConfig] = useState({
        key: 'provider_28east_id',
        direction: 'asc'
    });

    useEffect(() => {
        fetchProvider28East();
    }, []);

    const fetchProvider28East = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider-28east`);
            const sortedProviders = applySorting(response.data);
            setProvider28EastList(sortedProviders);
        } catch (error) {
            console.error("Error fetching 28east providers:", error);
        }
    };

    const openModal = (provider = null) => {
        if (provider) {
            setFormData({
                name_28east: provider.name_28east,
                status: provider.status
            });
            setEditingId(provider.provider_28east_id);
        } else {
            setFormData({
                name_28east: '',
                status: 'active'
            });
            setEditingId(null);
        }
        setShowModal(true);
    };

    const saveProvider28East = async (e) => {
        e.preventDefault();
        try {
            if (editingId) {
                await axios.put(`${process.env.REACT_APP_API_URL}/provider-28east/${editingId}`, formData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/provider-28east`, formData);
            }
            setShowModal(false);
            fetchProvider28East();
        } catch (error) {
            console.error("Error saving 28east provider:", error);
        }
    };

    const deleteProvider28East = async (id) => {
        try {
            // Check for linked providers
            const providersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/providers`);
            const allProviders = providersResponse.data;
            const linkedProviders = allProviders.filter(provider => 
                provider.Provider28Easts.some(p28e => p28e.provider_28east_id === id)
            );

            if (linkedProviders.length > 0) {
                alert(`This 28East provider cannot be deleted as it is linked to ${linkedProviders.length} provider(s).`);
                return;
            }

            if (window.confirm("Are you sure you want to delete this 28east provider?")) {
                await axios.delete(`${process.env.REACT_APP_API_URL}/provider-28east/${id}`);
                fetchProvider28East();
            }
        } catch (error) {
            console.error("Error deleting 28east provider:", error);
            alert("An error occurred while trying to delete the 28east provider.");
        }
    };

    const sortProviders = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setProvider28EastList(applySorting([...provider28EastList]));
    };

    const applySorting = (providersToSort) => {
        const { key, direction } = sortConfig;
        if (!key) return providersToSort;

        return [...providersToSort].sort((a, b) => {
            // Handle numeric values
            if (['provider_28east_id'].includes(key)) {
                return direction === 'asc' ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key]);
            }
            // Default string comparison
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    return (
        <div className="container mt-4">
            <h1>28East Providers</h1>
            <button className="btn btn-primary mb-3" onClick={() => openModal()}>Add 28East Provider</button>
            
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => sortProviders('provider_28east_id')} style={{cursor: 'pointer'}}>
                            ID {sortConfig.key === 'provider_28east_id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortProviders('name_28east')} style={{cursor: 'pointer'}}>
                            28East Name {sortConfig.key === 'name_28east' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortProviders('status')} style={{cursor: 'pointer'}}>
                            Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {provider28EastList.map(provider => (
                        <tr key={provider.provider_28east_id}>
                            <td>{provider.provider_28east_id}</td>
                            <td>{provider.name_28east}</td>
                            <td>{provider.status}</td>
                            <td>
                                <button className="btn btn-warning btn-sm me-2" onClick={() => openModal(provider)}>Edit</button>
                                <button className="btn btn-danger btn-sm" onClick={() => deleteProvider28East(provider.provider_28east_id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Adding/Editing 28East Provider */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{editingId ? 'Edit 28East Provider' : 'Add 28East Provider'}</h5>
                        </div>
                        <form onSubmit={saveProvider28East}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>28East Provider Name*</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        value={formData.name_28east}
                                        onChange={(e) => setFormData({ ...formData, name_28east: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Status*</label>
                                    <select 
                                        className="form-control"
                                        value={formData.status}
                                        onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                                    >
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="deleted">Deleted</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                                <button type="submit" className="btn btn-primary">{editingId ? 'Update' : 'Add'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Provider28East;