import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ImageUpload from './ImageUpload';

const PromotionCategories = () => {
    const [promotionCategories, setPromotionCategories] = useState([]);
    const [editingCategoryId, setEditingCategoryId] = useState(null);
    const [categoryData, setCategoryData] = useState({
        promotion_category_name: '',
        promotion_category_description: '',
        image_url: '',
        promotion_category_priority: 0,
        promotion_category_header_background_color: '#FFFFFF',
        promotion_category_header_text_color: '#000000',
        status: 'active'
    });
    const [showModal, setShowModal] = useState(false);
    const [imageUploadKey, setImageUploadKey] = useState(0);
    const imageUploadRef = useRef();
    const [sortConfig, setSortConfig] = useState({
        key: 'promotion_category_id',
        direction: 'asc'
    });

    useEffect(() => {
        fetchPromotionCategories();
    }, []);

    const applySorting = (categoriesToSort) => {
        const { key, direction } = sortConfig;
        if (!key) return categoriesToSort;

        return [...categoriesToSort].sort((a, b) => {
            // Handle numeric values
            if (['promotion_category_id', 'promotion_category_priority'].includes(key)) {
                return direction === 'asc' ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key]);
            }
            // Handle color values (hex codes)
            if (['promotion_category_header_background_color', 'promotion_category_header_text_color'].includes(key)) {
                const colorA = a[key] || '';
                const colorB = b[key] || '';
                return direction === 'asc' ? colorA.localeCompare(colorB) : colorB.localeCompare(colorA);
            }
            // Default string comparison
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const sortPromotionCategories = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setPromotionCategories(applySorting([...promotionCategories]));
    };

    const fetchPromotionCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotion-categories`);
            const sortedCategories = applySorting(response.data);
            setPromotionCategories(sortedCategories);
        } catch (error) {
            console.error("Error fetching promotion categories:", error);
        }
    };

    const handleInputChange = (e) => {
        setCategoryData({ ...categoryData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingCategoryId) {
                await axios.put(`${process.env.REACT_APP_API_URL}/promotion-categories/${editingCategoryId}`, categoryData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/promotion-categories`, categoryData);
            }
            fetchPromotionCategories();
            setShowModal(false);
            resetForm();
        } catch (error) {
            console.error("Error saving promotion category:", error);
        }
    };

    const startEditing = (category) => {
        setEditingCategoryId(category.promotion_category_id);
        setCategoryData(category);
        setShowModal(true);
    };

    const deleteCategory = async (id) => {
        try {
            // Check for linked promotional packages
            const packagesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/promotional-packages`);
            const linkedPackages = packagesResponse.data.filter(pkg => pkg.promotion_category_id === id);
    
            // Check for linked promotional bundles
            const bundlesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/promotional-bundles`);
            const linkedBundles = bundlesResponse.data.filter(bundle => bundle.promotion_category_id === id);
    
            if (linkedPackages.length > 0 || linkedBundles.length > 0) {
                alert(`This promotion category cannot be deleted as it is linked to ${linkedPackages.length} promotional package(s) and ${linkedBundles.length} promotional bundle(s).`);
                return;
            }
    
            if (window.confirm("Are you sure you want to delete this promotion category?")) {
                await axios.delete(`${process.env.REACT_APP_API_URL}/promotion-categories/${id}`);
                fetchPromotionCategories();
            }
        } catch (error) {
            console.error("Error deleting promotion category:", error);
            alert("An error occurred while trying to delete the promotion category.");
        }
    };

    const resetForm = (keepModalOpen = false) => {
        setEditingCategoryId(null);
        setCategoryData({
            promotion_category_name: '',
            promotion_category_description: '',
            image_url: '',
            promotion_category_priority: 0,
            promotion_category_header_background_color: '#FFFFFF',
            promotion_category_header_text_color: '#000000',
            status: 'active'
        });
        setImageUploadKey(prevKey => prevKey + 1);
        if (!keepModalOpen) {
            setShowModal(false);
        }
        if (imageUploadRef.current && imageUploadRef.current.resetImageUploadState) {
            imageUploadRef.current.resetImageUploadState();
        }
    };

    return (
        <div className="container mt-4">
            <h1>Promotion Categories</h1>
            <button className="btn btn-primary mb-3" onClick={() => setShowModal(true)}>Add Promotion Category</button>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => sortPromotionCategories('promotion_category_id')} style={{cursor: 'pointer'}}>
                            ID {sortConfig.key === 'promotion_category_id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortPromotionCategories('promotion_category_name')} style={{cursor: 'pointer'}}>
                            Name {sortConfig.key === 'promotion_category_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortPromotionCategories('promotion_category_description')} style={{cursor: 'pointer'}}>
                            Description {sortConfig.key === 'promotion_category_description' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th>Image</th>
                        <th onClick={() => sortPromotionCategories('promotion_category_priority')} style={{cursor: 'pointer'}}>
                            Priority {sortConfig.key === 'promotion_category_priority' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortPromotionCategories('promotion_category_header_background_color')} style={{cursor: 'pointer'}}>
                            Header BG Color {sortConfig.key === 'promotion_category_header_background_color' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortPromotionCategories('promotion_category_header_text_color')} style={{cursor: 'pointer'}}>
                            Header Text Color {sortConfig.key === 'promotion_category_header_text_color' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortPromotionCategories('status')} style={{cursor: 'pointer'}}>
                            Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {promotionCategories.map(category => (
                        <tr key={category.promotion_category_id}>
                            <td>{category.promotion_category_id}</td>
                            <td>{category.promotion_category_name}</td>
                            <td>{category.promotion_category_description}</td>
                            <td>
                                {category.image_url && (
                                    <img 
                                        src={process.env.REACT_APP_IMAGE_URL + '/' + category.image_url} 
                                        alt={category.promotion_category_name} 
                                        style={{ width: '100px', height: '100px', objectFit: 'contain', borderRadius: '5px' }} 
                                    />
                                ) || <span className='table-warning'>No Image</span>}
                            </td>
                            <td>{category.promotion_category_priority}</td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div 
                                        style={{ 
                                            width: '20px', 
                                            height: '20px', 
                                            backgroundColor: category.promotion_category_header_background_color,
                                            border: '1px solid #dee2e6',
                                            marginRight: '8px'
                                        }} 
                                    />
                                    {category.promotion_category_header_background_color}
                                </div>
                            </td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div 
                                        style={{ 
                                            width: '20px', 
                                            height: '20px', 
                                            backgroundColor: category.promotion_category_header_text_color,
                                            border: '1px solid #dee2e6',
                                            marginRight: '8px'
                                        }} 
                                    />
                                    {category.promotion_category_header_text_color}
                                </div>
                            </td>
                            <td>{category.status}</td>
                            <td>
                                <button className="btn btn-warning btn-sm me-2" onClick={() => startEditing(category)}>Edit</button>
                                <button className="btn btn-danger btn-sm" onClick={() => deleteCategory(category.promotion_category_id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Adding/Editing Promotion Category */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="promotionCategoryModalLabel" aria-hidden={!showModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="promotionCategoryModalLabel">{editingCategoryId ? 'Edit Promotion Category' : 'Add Promotion Category'}</h5>
                            <button type="button" className="close" onClick={() => resetForm()}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="form-group mb-3">
                                    <label>Name*</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="promotion_category_name"
                                        value={categoryData.promotion_category_name} 
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Description</label>
                                    <textarea 
                                        className="form-control" 
                                        name="promotion_category_description"
                                        value={categoryData.promotion_category_description} 
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Priority</label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        name="promotion_category_priority"
                                        value={categoryData.promotion_category_priority} 
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Header Background Color*</label>
                                    <div className="input-group">
                                        <input 
                                            type="color" 
                                            className="form-control form-control-color" 
                                            name="promotion_category_header_background_color"
                                            value={categoryData.promotion_category_header_background_color} 
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="promotion_category_header_background_color"
                                            value={categoryData.promotion_category_header_background_color} 
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Header Text Color*</label>
                                    <div className="input-group">
                                        <input 
                                            type="color" 
                                            className="form-control form-control-color" 
                                            name="promotion_category_header_text_color"
                                            value={categoryData.promotion_category_header_text_color} 
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="promotion_category_header_text_color"
                                            value={categoryData.promotion_category_header_text_color} 
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Status*</label>
                                    <select 
                                        className="form-control" 
                                        name="status"
                                        value={categoryData.status} 
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="deleted">Deleted</option>
                                    </select>
                                </div>
                                <label>Image not used currently</label>
                                <ImageUpload 
                                    ref={imageUploadRef}
                                    onUploadSuccess={(url) => setCategoryData({ ...categoryData, image_url: url })}
                                    identifier="promotion_category"
                                    category_id={5}
                                    key={imageUploadKey}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => resetForm()}>Close</button>
                                <button type="submit" className="btn btn-primary">
                                    {editingCategoryId ? 'Update Promotion Category' : 'Add Promotion Category'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromotionCategories;