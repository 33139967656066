import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faBuilding, 
    faList, 
    faNetworkWired, 
    faSitemap, 
    faBox, 
    faPlusSquare, 
    faBoxes, 
    faTags, 
    faPeopleGroup
} from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const Dashboard = () => {
    const pages = [
        { name: 'Providers', path: '/providers', icon: faBuilding },
        { name: 'Categories', path: '/categories', icon: faList },
        { name: 'Technology Classes', path: '/technology-classes', icon: faNetworkWired },
        { name: 'Provider Categories', path: '/provider-categories', icon: faSitemap },
        { name: 'Packages', path: '/packages', icon: faBox },
        { name: 'Value Added Products', path: '/valueaddedproducts', icon: faPlusSquare },
        { name: 'Bundles', path: '/bundles', icon: faBoxes },
        { name: 'Promotional Categories', path: '/promotion-categories', icon: faTags },
        { name: 'Promotional Packages', path: '/promotional-packages', icon: faTags },
        { name: 'Promotional Bundles', path: '/promotional-bundles', icon: faTags },
        { name: 'Branches', path: '/branches', icon: faBuilding },
        { name: 'Team Members', path: '/team-members', icon: faPeopleGroup },
    ];

    return (
        <div className="container mt-5">
            <h1 className="text-center mb-5">ISP Package Management Dashboard</h1>
            <div className="row">
                {pages.map((page, index) => (
                    <div key={index} className="col-md-3 mb-4">
                        <Link to={page.path} className="text-decoration-none">
                            <div className="card h-100 dashboard-card">
                                <div className="card-body text-center">
                                    <FontAwesomeIcon 
                                        icon={page.icon} 
                                        size="4x" 
                                        className="mb-3"
                                    />
                                    <h5 className="card-title">{page.name}</h5>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Dashboard;