import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ toggleTheme, isLoggedIn, currentUser, handleLogout }) => {
    return (
        <>
            <div className="top-bar bg-light py-2">
                <div className="container d-flex justify-content-between align-items-center">
                    <Link className="navbar-brand" to="/">
                        <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" style={{ height: '40px' }} />
                    </Link>
                    <div>
                        {isLoggedIn && (
                            <>
                                {JSON.parse(localStorage.getItem('user')).role === 'admin' && (
                                    <>
                                        <Link className="btn btn-outline-secondary me-2" to="/manage-users">Manage Users</Link>
                                        <Link to="/branches" className="btn btn-outline-secondary me-2">Branches</Link>
                                        <Link to="/team-members" className="btn btn-outline-secondary me-2">Team Members</Link>
                                    </>
                                )}
                                <Link to="/edit-user" className="btn btn-outline-secondary me-2">Edit Profile</Link>
                                <button className="btn btn-secondary me-2" onClick={toggleTheme}>Toggle Theme</button>
                                <span className="me-2">Welcome, {currentUser}</span>
                                <button className="btn btn-outline-primary" onClick={handleLogout}>Logout</button>
                            </>
                        )}
                        {!isLoggedIn && (
                            <Link to="/login" className="btn btn-primary">Login</Link>
                        )}
                    </div>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <div className="collapse navbar-collapse justify-content-center">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link className="nav-link" to="/providers">Providers</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/provider-28east">Provider 28East</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/categories">Categories</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/technology-classes">Tech Classes</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/provider-categories">Provider Categories</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/packages">Packages</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/valueaddedproducts">VAP</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/bundles">Bundles</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/promotion-categories">Promo Categories</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/promotional-packages">Promo Packages</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/promotional-bundles">Promo Bundles</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/news">News</Link>
                            </li>
                       

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
