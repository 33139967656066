// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.off-canvas {
    position: fixed;
    top: 0;
    left: -500px; /* Hide off-canvas by default */
    width: 500px;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: left 0.3s ease;
    z-index: 1000;
}

.off-canvas.show {
    left: 0; /* Slide in */
}

.off-canvas-header {
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
}

.off-canvas-body {
    padding: 1rem;
}

.btn-img-uploads {
    margin-top: 9px !important;
    margin-left: 10px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/ImageUpload.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,YAAY,EAAE,+BAA+B;IAC7C,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,wCAAwC;IACxC,0BAA0B;IAC1B,aAAa;AACjB;;AAEA;IACI,OAAO,EAAE,aAAa;AAC1B;;AAEA;IACI,aAAa;IACb,gCAAgC;AACpC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,0BAA0B;IAC1B,4BAA4B;AAChC","sourcesContent":[".off-canvas {\r\n    position: fixed;\r\n    top: 0;\r\n    left: -500px; /* Hide off-canvas by default */\r\n    width: 500px;\r\n    height: 100%;\r\n    background-color: white;\r\n    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);\r\n    transition: left 0.3s ease;\r\n    z-index: 1000;\r\n}\r\n\r\n.off-canvas.show {\r\n    left: 0; /* Slide in */\r\n}\r\n\r\n.off-canvas-header {\r\n    padding: 1rem;\r\n    border-bottom: 1px solid #dee2e6;\r\n}\r\n\r\n.off-canvas-body {\r\n    padding: 1rem;\r\n}\r\n\r\n.btn-img-uploads {\r\n    margin-top: 9px !important;\r\n    margin-left: 10px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
