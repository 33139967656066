import React, { useState, useEffect } from 'react';
import axios from 'axios';


const News = () => {
    const [news, setNews] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);
    const [error, setError] = useState(null);

    const initialFormState = {
        news_title: '',
        news_content: '',
        news_title_text_color: '#000000',
        news_title_background_color: '#FFFFFF',
        news_priority: 0,
        status: 'disabled'
    };

    const [formData, setFormData] = useState(initialFormState);

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/news`);
            setNews(response.data);
        } catch (error) {
            setError('Error fetching news items');
            console.error('Error:', error);
        }
    };

    const handleClose = () => {
        setShowModal(false);
        setSelectedNews(null);
        setFormData(initialFormState);
    };

    const handleShow = (newsItem = null) => {
        if (newsItem) {
            setSelectedNews(newsItem);
            setFormData(newsItem);
        } else {
            setSelectedNews(null);
            setFormData(initialFormState);
        }
        setShowModal(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (selectedNews) {
                await axios.put(`${process.env.REACT_APP_API_URL}/news/${selectedNews.news_id}`, formData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/news`, formData);
            }
            fetchNews();
            handleClose();
        } catch (error) {
            setError('Error saving news item');
            console.error('Error:', error);
        }
    };

    const handleDelete = async (newsId) => {
        if (window.confirm('Are you sure you want to delete this news item?')) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/news/${newsId}`);
                fetchNews();
            } catch (error) {
                setError('Error deleting news item');
                console.error('Error:', error);
            }
        }
    };

    return (
        <div className="container">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2>News Management</h2>
                <button className="btn btn-primary" onClick={() => handleShow()}>
                    Add News Item
                </button>
            </div>

            {error && <div className="alert alert-danger">{error}</div>}

            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Content</th>
                        <th>Priority</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {news.map((item) => (
                        <tr key={item.news_id}>
                            <td>
                                <div style={{
                                    backgroundColor: item.news_title_background_color,
                                    color: item.news_title_text_color,
                                    padding: '5px'
                                }}>
                                    {item.news_title}
                                </div>
                            </td>
                            <td>{item.news_content}</td>
                            <td>{item.news_priority}</td>
                            <td>{item.status}</td>
                            <td>
                                <button className="btn btn-warning btn-sm me-2" onClick={() => handleShow(item)}>
                                    Edit
                                </button>
                                <button className="btn btn-danger btn-sm" onClick={() => handleDelete(item.news_id)}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{selectedNews ? 'Edit News Item' : 'Add News Item'}</h5>
                            <button type="button" className="close" onClick={handleClose}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="form-group mb-3">
                                    <label>Title*</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="news_title"
                                        value={formData.news_title}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label>Content*</label>
                                    <textarea 
                                        className="form-control" 
                                        name="news_content"
                                        value={formData.news_content}
                                        onChange={handleInputChange}
                                        rows={3}
                                        required
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label>Title Text Color*</label>
                                    <div className="input-group">
                                        <input 
                                            type="color" 
                                            className="form-control form-control-color" 
                                            name="news_title_text_color"
                                            value={formData.news_title_text_color}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="news_title_text_color"
                                            value={formData.news_title_text_color}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form-group mb-3">
                                    <label>Title Background Color*</label>
                                    <div className="input-group">
                                        <input 
                                            type="color" 
                                            className="form-control form-control-color" 
                                            name="news_title_background_color"
                                            value={formData.news_title_background_color}
                                            onChange={handleInputChange}
                                            required
                                        />
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            name="news_title_background_color"
                                            value={formData.news_title_background_color}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form-group mb-3">
                                    <label>Priority</label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        name="news_priority"
                                        value={formData.news_priority}
                                        onChange={handleInputChange}
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label>Status*</label>
                                    <select 
                                        className="form-control" 
                                        name="status"
                                        value={formData.status}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="disabled">Disabled</option>
                                        <option value="active">Active</option>
                                        <option value="deleted">Deleted</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                                <button type="submit" className="btn btn-primary">
                                    {selectedNews ? 'Update News Item' : 'Add News Item'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default News;