import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageUpload from './ImageUpload';

const Providers = () => {
    const [providers, setProviders] = useState([]);
    const [providerData, setProviderData] = useState({
        provider_full_name: '',
        image_url: '',
        status: 'active',
        provider28east_ids: [], // New field for multiple 28east providers
    });
    const [editingProviderId, setEditingProviderId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [initialImageUrl, setInitialImageUrl] = useState(null); // Added state to store the initial image URL
    const [imageUploadKey, setImageUploadKey] = useState(0);
    const imageUploadRef = useRef();
    const [errors, setErrors] = useState({});
    const [provider28EastOptions, setProvider28EastOptions] = useState([]); // New state for 28east provider options
    const [sortConfig, setSortConfig] = useState({
        key: 'provider_id',
        direction: 'asc'
    });

    const fetchProvider28EastOptions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider-28east`);
            setProvider28EastOptions(response.data);
        } catch (error) {
            console.error("Error fetching 28east providers:", error);
        }
    };

    useEffect(() => {
        fetchProviders();
        fetchProvider28EastOptions();
    }, []);

    const fetchProviders = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/providers`);
            const sortedProviders = [...response.data].sort((a, b) => {
                const { key, direction } = sortConfig;
                // Handle numeric values
                if (['provider_id'].includes(key)) {
                    return direction === 'asc' ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key]);
                }
                // Handle 28East provider names
                if (key === 'provider28east_names') {
                    const namesA = a.Provider28Easts?.map(p => p.name_28east).join(', ') || '';
                    const namesB = b.Provider28Easts?.map(p => p.name_28east).join(', ') || '';
                    return direction === 'asc' ? namesA.localeCompare(namesB) : namesB.localeCompare(namesA);
                }
                // Default string comparison
                if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
                if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
                return 0;
            });
            setProviders(sortedProviders);
        } catch (error) {
            console.error("Error fetching providers:", error);
        }
    };

    const fetchProviderCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider-categories`);
            return response.data;
        } catch (error) {
            console.error("Error fetching provider categories:", error);
            return [];
        }
    };

    const openModal = (provider = null) => {
        if (provider) {
            setProviderData({
                provider_full_name: provider.provider_full_name,
                image_url: `${process.env.REACT_APP_IMAGE_URL}/${provider.image_url}`,
                status: provider.status,
                provider28east_ids: provider.Provider28Easts ? 
                    provider.Provider28Easts.map(p => p.provider_28east_id) : []
            });
            setEditingProviderId(provider.provider_id);
            setInitialImageUrl(provider.image_url);
        } else {
            resetForm(true);
        }
        setShowModal(true);
        if (imageUploadRef.current && imageUploadRef.current.resetImageUploadState) {
            imageUploadRef.current.resetImageUploadState();
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!providerData.provider_full_name.trim()) {
            newErrors.provider_full_name = 'Provider Full Name is required';
        }
        if (!providerData.image_url) {
            newErrors.image_url = 'Image is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const saveProvider = async () => {
        if (validateForm()) {
            try {
                if (editingProviderId) {
                    await axios.put(`${process.env.REACT_APP_API_URL}/providers/${editingProviderId}`, providerData);
                } else {
                    await axios.post(`${process.env.REACT_APP_API_URL}/providers`, providerData);
                }
                resetForm();
                fetchProviders();
            } catch (error) {
                console.error("Error saving provider:", error);
            }
        }
    };

    const resetForm = (keepModalOpen = false) => {
        setProviderData({
            provider_full_name: '',
            image_url: '',
            status: 'active',
            provider28east_ids: []
        });
        setEditingProviderId(null);
        setInitialImageUrl(null);
        setImageUploadKey(prevKey => prevKey + 1);
        if (!keepModalOpen) {
            setShowModal(false);
        }
        if (imageUploadRef.current && imageUploadRef.current.resetImageUploadState) {
            imageUploadRef.current.resetImageUploadState();
        }
    };

    const deleteProvider = async (id) => {
        try {
            const providerCategories = await fetchProviderCategories();
            const linkedCategories = providerCategories.filter(category => category.provider_id === id);

            if (linkedCategories.length > 0) {
                alert(`This provider cannot be deleted as it is linked to ${linkedCategories.length} provider categorie(s).`);
                return;
            }

            if (window.confirm("Are you sure you want to delete this provider?")) {
                await axios.delete(`${process.env.REACT_APP_API_URL}/providers/${id}`);
                fetchProviders();
            }
        } catch (error) {
            console.error("Error deleting provider:", error);
            alert("An error occurred while trying to delete the provider.");
        }
    };

    const sortProviders = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setProviders(applySorting([...providers]));
    };

    const applySorting = (providersToSort) => {
        const { key, direction } = sortConfig;
        if (!key) return providersToSort;

        return [...providersToSort].sort((a, b) => {
            // Handle numeric values
            if (['provider_id'].includes(key)) {
                return direction === 'asc' ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key]);
            }
            // Handle 28East provider names
            if (key === 'provider28east_names') {
                const namesA = a.Provider28Easts?.map(p => p.name_28east).join(', ') || '';
                const namesB = b.Provider28Easts?.map(p => p.name_28east).join(', ') || '';
                return direction === 'asc' ? namesA.localeCompare(namesB) : namesB.localeCompare(namesA);
            }
            // Default string comparison
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    console.log('Providers component loaded'); // Add a console log to check if the Providers component is loaded

    return (
        <div className="container mt-4">
            <h1>Providers</h1>
            <button className="btn btn-primary mb-3" onClick={() => openModal()}>Add Provider</button>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => sortProviders('provider_id')} style={{cursor: 'pointer'}}>
                            ID {sortConfig.key === 'provider_id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortProviders('provider_full_name')} style={{cursor: 'pointer'}}>
                            Provider Full Name {sortConfig.key === 'provider_full_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th>28 East Provider Names</th>
                        <th>Image</th>
                        <th onClick={() => sortProviders('status')} style={{cursor: 'pointer'}}>
                            Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {providers.map(provider => (
                        <tr key={provider.provider_id}>
                            <td>{provider.provider_id}</td>
                            <td>{provider.provider_full_name}</td>
                            <td>
                                {provider.Provider28Easts ? 
                                    provider.Provider28Easts.map(p28e => (
                                        <div key={p28e.provider_28east_id}>
                                            {p28e.name_28east}
                                        </div>
                                    )) : 'No 28East names'
                                }
                            </td>
                            <td>
                                <img 
                                    src={`${process.env.REACT_APP_IMAGE_URL}/${provider.image_url}`} 
                                    alt={provider.provider_full_name} 
                                    style={{ width: '100px', height: '100px', objectFit: 'contain', borderRadius: '5px' }} 
                                />
                            </td>
                            <td>{provider.status}</td>
                            <td>
                                <button className="btn btn-warning btn-sm me-2" onClick={() => openModal(provider)}>Edit</button>
                                <button className="btn btn-danger btn-sm" onClick={() => deleteProvider(provider.provider_id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Adding/Editing Provider */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="providerModalLabel" aria-hidden={!showModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="providerModalLabel">{editingProviderId ? 'Edit Provider' : 'Add Provider'}</h5>
                        </div>
                        <div className="modal-body">
                           
                            <form onSubmit={(e) => { e.preventDefault(); saveProvider(); }}>
                                <div className="form-group">
                                    <label>Provider Full Name*</label>
                                    <input 
                                        type="text" 
                                        className={`form-control ${errors.provider_full_name ? 'is-invalid' : ''}`} 
                                        value={providerData.provider_full_name} 
                                        onChange={(e) => setProviderData({ ...providerData, provider_full_name: e.target.value })} 
                                        required
                                    />
                                    {errors.provider_full_name && <div className="invalid-feedback">{errors.provider_full_name}</div>}
                                </div>
                           
                                <div className="form-group">
                                    <label>Provider Logo (150x150)*</label>
                                    <div className="form-group">
                                        {initialImageUrl && (
                                            <img 
                                                src={`http://localhost:3008/images/${initialImageUrl}`} 
                                                alt="Current Image" 
                                                style={{ width: '100px', objectFit: 'contain', height: '100px' }} 
                                            />
                                        )}
                                    </div>
                                </div>

                                <ImageUpload 
                                    ref={imageUploadRef}
                                    onUploadSuccess={(url) => setProviderData({ ...providerData, image_url: url })} 
                                    identifier="provider"
                                    key={imageUploadKey}
                                    category_id={1}
                                    required
                                />
                                {errors.image_url && <div className="text-danger">{errors.image_url}</div>}

                                <div className="form-group">
                                    <label>Status*</label>
                                    <select className="form-control" value={providerData.status} onChange={(e) => setProviderData({ ...providerData, status: e.target.value })}>
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="deleted">Deleted</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>28East Provider Names*</label>
                                    <select 
                                        multiple 
                                        className="form-control" 
                                        value={providerData.provider28east_ids}
                                        onChange={(e) => {
                                            const selectedOptions = Array.from(e.target.selectedOptions, option => parseInt(option.value));
                                            setProviderData({ ...providerData, provider28east_ids: selectedOptions });
                                        }}
                                        required
                                    >
                                        {provider28EastOptions.map(p28e => (
                                            <option key={p28e.provider_28east_id} value={p28e.provider_28east_id}>
                                                {p28e.name_28east}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => resetForm()}>Close</button>
                                    <button type="submit" className="btn btn-primary">{editingProviderId ? 'Update Provider' : 'Add Provider'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Providers;
