import React from 'react';
import TechnologyClasses from '../components/TechnologyClasses';
import 'bootstrap/dist/css/bootstrap.min.css';

const TechnologyClassesPage = () => {
    return (
        <div className="container">
            
            <TechnologyClasses />
        </div>
    );
};

export default TechnologyClassesPage;