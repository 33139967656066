import React from 'react';
import Provider28East from '../components/Provider28East';

const Provider28EastPage = () => {
    return (
        <div className="container">
            
            <Provider28East />
        </div>
    );
};

export default Provider28EastPage;