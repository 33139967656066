import React from 'react';
import PromotionCategories from '../components/PromotionCategories';

const PromotionCategoriesPage = () => {
    return (
        <div className="container">
            
            <PromotionCategories />
        </div>
    );
};

export default PromotionCategoriesPage;