// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-header {
    min-width: 200px;
}

.form-select-sm {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
}

.provider-category-header:hover {
    background-color: #f8f9fa;
    border-radius: 5px;
}

.provider-category-title {
    color: #666;
    border-bottom: 2px solid #eee;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
}

.provider-category-header {
    transition: background-color 0.2s;
    padding: 0.5rem;
}

.collapse {
    transition: height 0.3s ease-out;
}

.fas {
    transition: transform 0.2s;
}

.fa-chevron-down {
    transform: rotate(0deg);
}

.fa-chevron-right {
    transform: rotate(0deg);
}
`, "",{"version":3,"sources":["webpack://./src/components/PromotionalBundles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,6BAA6B;IAC7B,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,iCAAiC;IACjC,eAAe;AACnB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":[".sort-header {\n    min-width: 200px;\n}\n\n.form-select-sm {\n    font-size: 0.875rem;\n    padding: 0.25rem 0.5rem;\n}\n\n.provider-category-header:hover {\n    background-color: #f8f9fa;\n    border-radius: 5px;\n}\n\n.provider-category-title {\n    color: #666;\n    border-bottom: 2px solid #eee;\n    padding-bottom: 0.5rem;\n    margin-bottom: 0;\n}\n\n.provider-category-header {\n    transition: background-color 0.2s;\n    padding: 0.5rem;\n}\n\n.collapse {\n    transition: height 0.3s ease-out;\n}\n\n.fas {\n    transition: transform 0.2s;\n}\n\n.fa-chevron-down {\n    transform: rotate(0deg);\n}\n\n.fa-chevron-right {\n    transform: rotate(0deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
