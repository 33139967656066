import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Packages = () => {
    const [packages, setPackages] = useState([]);
    const [providers, setProviders] = useState([]);
    const [categories, setCategories] = useState([]);
    const [providerCategories, setProviderCategories] = useState([]);
    const [packageData, setPackageData] = useState({
        prov_cat_id: '',
        term: '',
        download_speed_mb: '',
        upload_speed_mb: '',
        cost_price_incl: '',
        sell_price_incl: '',
        installation: '',
        installation_cost: '',
        status: 'Active',
        package_priority: '',
        package_name: ''
    });
    const [editingPackageId, setEditingPackageId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [sortConfigs, setSortConfigs] = useState(() => {
        const savedSortConfigs = Cookies.get('packagesSortConfigs');
        return savedSortConfigs ? JSON.parse(savedSortConfigs) : [
            { key: 'package_id', direction: 'asc' },
            { key: '', direction: 'asc' },
            { key: '', direction: 'asc' }
        ];
    });
    const navigate = useNavigate();
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    const sortOptions = [
        { value: '', label: 'None' },
        { value: 'package_id', label: 'Package ID' },
        { value: 'package_name', label: 'Package Name' },
        { value: 'term', label: 'Term' },
        { value: 'download_speed_mb', label: 'Download Speed' },
        { value: 'upload_speed_mb', label: 'Upload Speed' },
        { value: 'cost_price_incl', label: 'Cost Price' },
        { value: 'sell_price_incl', label: 'Sell Price' },
        { value: 'installation', label: 'Installation' },
        { value: 'status', label: 'Status' },
        { value: 'package_priority', label: 'Priority' }
    ];

    useEffect(() => {
        fetchPackages();
        fetchProviders();
        fetchCategories();
        fetchProviderCategories();
    }, []);

    const fetchPackages = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/packages`);
            const savedSortConfigs = Cookies.get('packagesSortConfigs');
            const currentSortConfigs = savedSortConfigs ? JSON.parse(savedSortConfigs) : sortConfigs;
            
            const sortedPackages = applySorting(response.data, currentSortConfigs);
            setPackages(sortedPackages);
        } catch (error) {
            console.error("Error fetching packages:", error);
        }
    };

    const fetchProviders = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/providers`);
        setProviders(response.data);
    };

    const fetchCategories = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
        setCategories(response.data);
    };

    const fetchProviderCategories = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider-categories`);
        setProviderCategories(response.data);
    };

    const addOrUpdatePackage = async (e) => {
        e.preventDefault();
        try {
            if (editingPackageId) {
                await axios.put(`${process.env.REACT_APP_API_URL}/packages/${editingPackageId}`, packageData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/packages`, packageData);
            }
            resetForm();
            fetchPackages();
        } catch (error) {
            console.error("Error saving package:", error);
        }
    };

    const handleDownloadSpeedChange = (e) => {
        const value = e.target.value;
        setPackageData({ ...packageData, download_speed_mb: value  });
    };

    const handleUploadSpeedChange = (e) => {
        const value = e.target.value;
        setPackageData({ ...packageData, upload_speed_mb: value  });
    };

    const deletePackage = async (id) => {
        try {
            // Check for regular bundles
            const bundlesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/bundles`);
            const allBundles = bundlesResponse.data;
            const linkedBundles = allBundles.filter(bundle => bundle.package_id === id);

            // Check for promotional bundles
            const promoBundlesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/promotional-bundles`);
            const allPromoBundles = promoBundlesResponse.data;
            const linkedPromoBundles = allPromoBundles.filter(bundle => bundle.package_id === id);

            // Check for promotional packages
            const promoPackagesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/promotional-packages`);
            const allPromoPackages = promoPackagesResponse.data;
            const linkedPromoPackages = allPromoPackages.filter(pkg => pkg.package_id === id);

            if (linkedBundles.length > 0 || linkedPromoBundles.length > 0 || linkedPromoPackages.length > 0) {
                alert(`This package cannot be deleted as it has:
                ${linkedBundles.length} regular bundle(s)
                ${linkedPromoBundles.length} promotional bundle(s)
                ${linkedPromoPackages.length} promotional package(s) assigned to it.`);
                return;
            }

            if (window.confirm("Are you sure you want to delete this package?")) {
                await axios.delete(`${process.env.REACT_APP_API_URL}/packages/${id}`);
                fetchPackages();
            }
        } catch (error) {
            console.error("Error deleting package:", error);
            alert("An error occurred while trying to delete the package.");
        }
    };

    const startEditing = (pkg) => {
        setPackageData({
            prov_cat_id: pkg.prov_cat_id,
            term: pkg.term,
            download_speed_mb: pkg.download_speed_mb,
            upload_speed_mb: pkg.upload_speed_mb,
            cost_price_incl: pkg.cost_price_incl,
            sell_price_incl: pkg.sell_price_incl,
            installation: pkg.installation,
            installation_cost: pkg.installation_cost,
            status: pkg.status,
            package_priority: pkg.package_priority,
            package_name: pkg.package_name,
            package_description: pkg.package_description
        });
        setEditingPackageId(pkg.package_id);
        setShowModal(true);
    };

    const resetForm = () => {
        setPackageData({
            prov_cat_id: '',
            term: '',
            download_speed_mb: '',
            upload_speed_mb: '',
            cost_price_incl: '',
            sell_price_incl: '',
            installation: '',
            installation_cost: '',
            status: '',
            package_priority: '',
            package_name: '',
            package_description: ''
        });
        setEditingPackageId(null);
        setShowModal(false);
    };

    const copyPackage = (pkg) => {
        setPackageData({
            prov_cat_id: pkg.prov_cat_id,
            term: pkg.term,
            download_speed_mb: pkg.download_speed_mb,
            upload_speed_mb: pkg.upload_speed_mb,
            cost_price_incl: pkg.cost_price_incl,
            sell_price_incl: pkg.sell_price_incl,
            installation: pkg.installation,
            installation_cost: pkg.installation_cost,
            status: pkg.status,
            package_priority: pkg.package_priority,
            package_name: pkg.package_name,
            package_description: pkg.package_description
        });
        setShowModal(true);
    };

    const setDefaultPackage = async (packageId, provCatId) => {
        try {
            if (window.confirm("Set this as the default package? This will remove default status from any other package in this category.")) {
                await axios.put(`${process.env.REACT_APP_API_URL}/packages/${packageId}/set-default`, {
                    prov_cat_id: provCatId
                });
                fetchPackages(); // Refresh the list
            }
        } catch (error) {
            console.error("Error setting default package:", error);
            alert("An error occurred while setting the default package.");
        }
    };

    const applySorting = (packagesToSort, configs = sortConfigs) => {
        return [...packagesToSort].sort((a, b) => {
            for (const config of configs) {
                if (!config.key) continue;
                
                const { key, direction } = config;
                let comparison = 0;
                
                if (['package_id', 'download_speed_mb', 'upload_speed_mb', 'cost_price_incl', 'sell_price_incl', 'installation', 'package_priority'].includes(key)) {
                    comparison = direction === 'asc' ? a[key] - b[key] : b[key] - a[key];
                } else {
                    if (a[key] < b[key]) comparison = direction === 'asc' ? -1 : 1;
                    if (a[key] > b[key]) comparison = direction === 'asc' ? 1 : -1;
                }
                
                if (comparison !== 0) return comparison;
            }
            return 0;
        });
    };

    const truncateDescription = (text, id) => {
        if (!text) return '';
        if (expandedDescriptions[id]) return text;
        
        return text.length > 50 ? (
            <span>
                {text.substring(0, 50)}...
                <a 
                    href="#" 
                    onClick={(e) => {
                        e.preventDefault();
                        setExpandedDescriptions(prev => ({
                            ...prev,
                            [id]: true
                        }));
                    }}
                    className="text-primary ms-1"
                >
                    more
                </a>
            </span>
        ) : text;
    };

    return (
        <div className="container mt-4">
            <h1>Packages</h1>
            <button className="btn btn-primary mb-3" onClick={() => { resetForm(); setShowModal(true); }}>Add Package</button>
            
            <div className="row mb-3">
                {[0, 1, 2].map((index) => (
                    <div key={index} className="col-md-4">
                        <div className="d-flex align-items-center">
                            <select 
                                className="form-control me-2"
                                value={sortConfigs[index].key}
                                onChange={(e) => {
                                    const newConfigs = [...sortConfigs];
                                    newConfigs[index] = { ...newConfigs[index], key: e.target.value };
                                    setSortConfigs(newConfigs);
                                    Cookies.set('packagesSortConfigs', JSON.stringify(newConfigs), { expires: 30 });
                                    setPackages(applySorting([...packages]));
                                }}
                            >
                                {sortOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {`Sort ${index + 1}: ${option.label}`}
                                    </option>
                                ))}
                            </select>
                            <button 
                                className="btn btn-outline-secondary"
                                onClick={() => {
                                    const newConfigs = [...sortConfigs];
                                    newConfigs[index].direction = newConfigs[index].direction === 'asc' ? 'desc' : 'asc';
                                    setSortConfigs(newConfigs);
                                    setPackages(applySorting([...packages], newConfigs));
                                    Cookies.set('packagesSortConfigs', JSON.stringify(newConfigs), { expires: 30 });
                                }}
                            >
                                {sortConfigs[index].direction === 'asc' ? '↑' : '↓'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            
            {providerCategories
                .sort((a, b) => {
                    const providerA = providers.find(p => p.provider_id === a.provider_id);
                    const providerB = providers.find(p => p.provider_id === b.provider_id);
                    const categoryA = categories.find(c => c.cat_id === a.cat_id);
                    const categoryB = categories.find(c => c.cat_id === b.cat_id);

                    const nameA = (providerA?.provider_full_name || 'Unknown') + ' ' + (categoryA?.cat_name || 'Unknown');
                    const nameB = (providerB?.provider_full_name || 'Unknown') + ' ' + (categoryB?.cat_name || 'Unknown');

                    return nameA.localeCompare(nameB);
                })
                .map(providerCategory => {
                    const filteredPackages = packages.filter(pkg => pkg.prov_cat_id === providerCategory.prov_cat_id);
                    return (
                        <div key={providerCategory.prov_cat_id}>
                            <h2>{providerCategory.prov_cat_name || 'Unknown'}</h2>
                            {filteredPackages.length === 0 ? (
                                <p>No Packages added yet</p>
                            ) : (
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Package ID</th>
                                            <th>Package Name</th>
                                            <th>Description</th>
                                            <th>Term</th>
                                            <th>Download Speed</th>
                                            <th>Upload Speed</th>
                                            <th>Cost Price</th>
                                            <th>Sell Price</th>
                                            <th>Installation Cost</th>
                                            <th>Installation</th>
                                            <th>Status</th>
                                            <th>Priority</th>
                                            <th>Default</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredPackages.map(pkg => (
                                            <tr key={pkg.package_id}>
                                                <td>{pkg.package_id}</td>
                                                <td>{pkg.package_name}</td>
                                                <td>
                                                    {truncateDescription(pkg.package_description, pkg.package_id)}
                                                    {expandedDescriptions[pkg.package_id] && (
                                                        <a 
                                                            href="#" 
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setExpandedDescriptions(prev => ({
                                                                    ...prev,
                                                                    [pkg.package_id]: false
                                                                }));
                                                            }}
                                                            className="text-primary ms-1"
                                                        >
                                                            less
                                                        </a>
                                                    )}
                                                </td>
                                                <td>{pkg.term}</td>
                                                <td>{pkg.download_speed_mb} Mbps</td>
                                                <td>{pkg.upload_speed_mb} Mbps</td>
                                                <td>R{pkg.cost_price_incl}</td>
                                                <td>R{pkg.sell_price_incl}</td>
                                                <td>R{pkg.installation_cost}</td>
                                                <td>R{pkg.installation}</td>
                                                <td>{pkg.status}</td>
                                                <td>{pkg.package_priority}</td>
                                                <td>  <button 
                                                        className={`btn btn-sm ${pkg.package_default ? 'btn-success' : 'btn-outline-success'}`}
                                                        onClick={() => setDefaultPackage(pkg.package_id, pkg.prov_cat_id)}
                                                        title={pkg.package_default ? 'Default Package' : 'Set as Default'}
                                                    >
                                                        <i className="fas fa-star"></i>
                                                    </button></td>
                                                <td >

                                                <div className="d-flex align-items-center">
                                                    <button className="btn btn-warning btn-sm me-2" onClick={() => startEditing(pkg)}>Edit</button>
                                                    <button className="btn btn-danger btn-sm me-2" onClick={() => deletePackage(pkg.package_id)}>Delete</button>
                                                    <button className="btn btn-info btn-sm me-2" onClick={() => copyPackage(pkg)}>Copy</button>
                                                    <button 
                                                        className="btn btn-success btn-sm me-2" 
                                                        onClick={() => {
                                                            localStorage.setItem('createBundle', JSON.stringify({
                                                                package_id: pkg.package_id,
                                                                prov_cat_id: pkg.prov_cat_id,
                                                                package_description: pkg.package_description || '',
                                                                package_name: pkg.package_name || '',
                                                                showModal: true
                                                            }));
                                                            navigate('/bundles');
                                                        }}
                                                    >
                                                        Bundle
                                                    </button>
                                                    <button 
                                                        className="btn btn-primary btn-sm" 
                                                        onClick={() => {
                                                            localStorage.setItem('createPromotionalPackage', JSON.stringify({
                                                                package_id: pkg.package_id,
                                                                prov_cat_id: pkg.prov_cat_id,
                                                                showModal: true
                                                            }));
                                                            navigate('/promotional-packages');
                                                        }}
                                                    >
                                                        Promotional
                                                    </button>
                                                </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    );
                })}
            
            {/* Modal for Adding/Editing Package */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="packageModalLabel" aria-hidden={!showModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="packageModalLabel">
                                {editingPackageId ? 'Edit Package' : (packageData.prov_cat_id ? 'Copy Package' : 'Add Package')}
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={addOrUpdatePackage}>
                                <div className="form-group">
                                    <label>Provider Category*</label>
                                    <select className="form-control" value={packageData.prov_cat_id} onChange={(e) => setPackageData({ ...packageData, prov_cat_id: e.target.value })} required>
                                        <option value="">Select Provider Category</option>
                                        {providerCategories.map(providerCategory => (
                                            <option key={providerCategory.prov_cat_id} value={providerCategory.prov_cat_id}>
                                                {providerCategory.prov_cat_name || 'Unknown'}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Download Speed (Mbps)*</label>
                                    <input type="number" className="form-control" value={packageData.download_speed_mb} onChange={handleDownloadSpeedChange} required />
                                </div>
                                <div className="form-group">
                                    <label>Upload Speed (Mbps)*</label>
                                    <input type="number" className="form-control" value={packageData.upload_speed_mb} onChange={handleUploadSpeedChange} required />
                                </div>
                                <div className="form-group">
                                    <label>Package Name*</label>
                                    <input type="text" className="form-control" value={packageData.package_name} onChange={(e) => setPackageData({ ...packageData, package_name: e.target.value })} required />
                                </div>
                                <div className="form-group">
                                    <label>Package Description</label>
                                    <textarea 
                                        className="form-control" 
                                        value={packageData.package_description || ''} 
                                        onChange={(e) => setPackageData({ ...packageData, package_description: e.target.value })}
                                        rows="3"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Term*</label>
                                    <input type="text" className="form-control" value={packageData.term} onChange={(e) => setPackageData({ ...packageData, term: e.target.value })} required />
                                </div>
                                <div className="form-group">
                                    <label>Cost Price (Incl)*</label>
                                    <input type="number" className="form-control" value={packageData.cost_price_incl} onChange={(e) => setPackageData({ ...packageData, cost_price_incl: e.target.value })} required />
                                </div>
                                <div className="form-group">
                                    <label>Sell Price (Incl)*</label>
                                    <input type="number" className="form-control" value={packageData.sell_price_incl} onChange={(e) => setPackageData({ ...packageData, sell_price_incl: e.target.value })} required />
                                </div>
                                <div className="form-group">
                                    <label>Installation Cost*</label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        value={packageData.installation_cost} 
                                        onChange={(e) => setPackageData({ ...packageData, installation_cost: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Installation*</label>
                                    <input type="number" className="form-control" value={packageData.installation} onChange={(e) => setPackageData({ ...packageData, installation: e.target.value })} required />
                                </div>
                                <div className="form-group">
                                    <label>Package Priority*</label>
                                    <input type="number" className="form-control" value={packageData.package_priority} onChange={(e) => setPackageData({ ...packageData, package_priority: e.target.value })} required />
                                </div>
                                <div className="form-group">
                                    <label>Status*</label>
                                    <select className="form-control" value={packageData.status} onChange={(e) => setPackageData({ ...packageData, status: e.target.value })} required>
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                        <option value="pending">Pending</option>
                                    </select>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                                    <button type="submit" className="btn btn-primary">{editingPackageId ? 'Update Package' : 'Add Package'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Packages;
