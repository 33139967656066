import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form } from 'react-bootstrap';
const ManageUsers = () => {
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editingUser, setEditingUser] = useState(null);
    const [userData, setUserData] = useState({ email: '', password: '', role: 'user' });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`);
            setUsers(response.data);
        } catch (error) {
            setError('Error fetching users');
        }
    };

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
           
            if (editingUser) {
                const { password, ...updateData } = userData;
                if (editingUser.role !== userData.role) {
                    // Only update the role
                    await axios.put(`${process.env.REACT_APP_API_URL}/users/${editingUser.id}/role`, { role: userData.role });
                } else {
                    // Update other user data
                    await axios.put(`${process.env.REACT_APP_API_URL}/users/${editingUser.id}`, updateData);
                }
                setSuccess('User updated successfully');
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/users/register`, userData);
                setSuccess('User added successfully');
            }
            resetModalState();
            fetchUsers();
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred');
        }
    };

    const handleEdit = (user) => {
        setEditingUser(user);
        setUserData({ email: user.email, password: '', role: user.role });
        setShowModal(true);
    };

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/users/${id}`);
                setSuccess('User deleted successfully');
                fetchUsers();
            } catch (error) {
                setError('Error deleting user');
            }
        }
    };

    const resetModalState = () => {
        setEditingUser(null);
        setUserData({ email: '', password: '', role: 'user' });
        setShowModal(false);
    };

    return (
        <div className="container mt-5">
            <h2 className="mb-4">Manage Users</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            <Button variant="primary" onClick={() => { resetModalState(); setShowModal(true); }} className="mb-3">
                Add New User
            </Button>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td>
                                <Button variant="info" size="sm" onClick={() => handleEdit(user)} className="me-2">
                                    Edit
                                </Button>
                                <Button variant="danger" size="sm" onClick={() => handleDelete(user.id)}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingUser ? 'Edit User' : 'Add New User'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={userData.email}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        {!editingUser && (
                            <Form.Group className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={userData.password}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        )}
                        <Form.Group className="mb-3">
                            <Form.Label>Role</Form.Label>
                            <Form.Select
                                name="role"
                                value={userData.role}
                                onChange={handleChange}
                            >
                                <option value="user">User</option>
                                <option value="admin">Admin</option>
                            </Form.Select>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {editingUser ? 'Update User' : 'Add User'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ManageUsers;
