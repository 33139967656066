import React from 'react';
import PromotionalBundles from '../components/PromotionalBundles';

const PromotionalBundlesPage = () => {
    return (
        <div className="container mt-4">
            <PromotionalBundles />
        </div>
    );
};

export default PromotionalBundlesPage;