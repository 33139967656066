import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ImageUpload.css';

const imageApi = axios.create({
    baseURL: process.env.REACT_APP_IMAGE_URL,
   
});

const ImageUpload = React.forwardRef(({ onUploadSuccess, identifier, initialImageUrl, key, category_id }, ref) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageUrl, setImageUrl] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    const [uploadedImages, setUploadedImages] = useState([]);
    const [showOffCanvas, setShowOffCanvas] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const imagesPerPage = 9;

    useEffect(() => {
        fetchUploadedImages();
    }, []);

    // Reset the selected image when a new provider is added
    useEffect(() => {
        setImageUrl('');
    }, [identifier]);

    // Set initial image URL when editing an existing provider
    useEffect(() => {
        if (initialImageUrl) {
            setImageUrl(initialImageUrl);
        }
    }, [initialImageUrl]);

    useEffect(() => {
        console.log('uploadedImages:', uploadedImages);
    }, [uploadedImages]);

    const fetchUploadedImages = async () => {
        try {
            const response = await imageApi.post('/by-categories', {
                categoryIds: category_id ? [category_id] : []
            });
            
            if (Array.isArray(response.data.images)) {
                setUploadedImages(response.data.images);
            } else {
                console.error('Unexpected response format:', response.data);
                setUploadedImages([]);
            }
        } catch (error) {
            console.error('Error fetching uploaded images:', error);
            setUploadedImages([]);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        setError('');
    };

    const handleUpload = async (event) => {
        event.preventDefault();
        if (!selectedFile) {
            setError('Please select a file to upload.');
            return;
        }

        setIsUploading(true);
        const formData = new FormData();
        formData.append('image', selectedFile);
        
        console.log('Category ID:', category_id);
        
        if (typeof category_id !== 'undefined' && category_id !== null) {
            formData.append('category_id', category_id.toString());
            for (let pair of formData.entries()) {
                console.log(pair[0] + ': ' + pair[1]);
            }
        } else {
            setError('Category ID is required');
            setIsUploading(false);
            return;
        }

        try {
            const response = await imageApi.post('/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Upload response:', response.data);
            const imageId = response.data.imageId;
            setImageUrl(imageId);
            if (onUploadSuccess) {
                onUploadSuccess(imageId);
            }
            fetchUploadedImages();
        } catch (error) {
            console.error('Upload error details:', error.response?.data);
            if (error.response) {
                setError(error.response.data.error || 'Error uploading the file. Please try again.');
            } else {
                setError('Error uploading the file. Please try again.');
            }
        } finally {
            setIsUploading(false);
        }
    };

    const handleImageSelect = (imageId) => {
        setImageUrl(imageId);
        if (onUploadSuccess) {
            onUploadSuccess(imageId);
        }
    };

    const toggleOffCanvas = () => {
        setShowOffCanvas(!showOffCanvas);
    };

    // Pagination logic
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = Array.isArray(uploadedImages) 
        ? uploadedImages.slice(indexOfFirstImage, indexOfLastImage) 
        : [];
    const totalPages = Math.ceil((uploadedImages?.length || 0) / imagesPerPage);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const resetImageUploadState = () => {
        setSelectedFile(null);
        setImageUrl('');
        setError('');
        setUploadedImages([]);
        setShowOffCanvas(false);
        setCurrentPage(1);
    };

    // Helper function to get full image URL
    const getFullImageUrl = (imageId) => {
        return `${process.env.REACT_APP_IMAGE_URL}/${imageId}`;
    };

    return (
        <div className="mb-3">
            <label>Upload Image</label>
            <input type="file" className="form-control" onChange={handleFileChange} />
            <button className="btn btn-primary mt-2" onClick={handleUpload} disabled={isUploading}>
                {isUploading ? 'Uploading...' : 'Upload'}
            </button>
            {error && <div className="text-danger mt-2">{error}</div>}
            {imageUrl && <img src={getFullImageUrl(imageUrl)} alt="Uploaded" className="img-thumbnail mt-2" style={{ width: '100px' }} />}

            <button className="btn btn-secondary mt-3 btn-img-uploads" onClick={(event) => { event.preventDefault(); toggleOffCanvas(); }}>
                {showOffCanvas ? 'Hide Uploaded Images' : 'Show Uploaded Images'}
            </button>

            <div className={`off-canvas ${showOffCanvas ? 'show' : ''}`}>
                <div className="off-canvas-header">
                    <h5>Uploaded Images</h5>
                    <button className="btn-close" onClick={(event) => { event.preventDefault(); toggleOffCanvas(); }}></button>
                </div>
                <div className="off-canvas-body">
                    <div className="row">
                        {Array.isArray(currentImages) && currentImages.map((image) => (
                            <div key={image.image_id} className="col-4 mb-2">
                                <img
                                    src={getFullImageUrl(image.image_id)}
                                    alt={image.filename}
                                    className="img-thumbnail"
                                    style={{ cursor: 'pointer', width: '100%', height: '100px', objectFit: 'contain' }}
                                    onClick={() => handleImageSelect(image.image_id)}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                        <button className="btn btn-secondary" onClick={prevPage} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <button className="btn btn-secondary" onClick={nextPage} disabled={currentPage === totalPages}>
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export const resetImageUploadState = () => {
    // This is a dummy function that will be replaced in components using ImageUpload
    console.warn('resetImageUploadState called from outside ImageUpload component');
};

export default ImageUpload;
