import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './PromotionalBundles.css';

// Add cookie utility functions
const setCookie = (name, value, days = 7) => {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    document.cookie = name + '=' + encodeURIComponent(JSON.stringify(value)) + '; expires=' + expires + '; path=/';
};

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        const cookieValue = parts.pop().split(';').shift();
        try {
            return JSON.parse(decodeURIComponent(cookieValue));
        } catch (e) {
            return null;
        }
    }
    return null;
};

// Add this function near the top of the component
const formatDateToGMTPlus2 = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    date.setHours(date.getHours() + 2); // Adjust to GMT+2
    return date.toLocaleString('en-ZA', { 
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC'
    });
};

const PromotionalBundles = () => {
    const [promotionalBundles, setPromotionalBundles] = useState([]);
    const [packages, setPackages] = useState([]);
    const [promotionCategories, setPromotionCategories] = useState([]);
    const [valueAddedProducts, setValueAddedProducts] = useState([]);
    const [bundleData, setBundleData] = useState({
        promotion_category_id: '',
        package_id: '',
        bundle_name: '',
        bundle_description: '',
        package_price: '',
        installation_price: '',
        bundle_priority: '',
        active_from: '',
        active_until: '',
        status: 'active',
        valueAddedProducts: []
    });
    const [editingBundleId, setEditingBundleId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedPackageDetails, setSelectedPackageDetails] = useState(null);
    const [valueAddedProductsData, setValueAddedProductsData] = useState([{ vap_id: '', quantity: 1, price: 0, vap_cost: null, vap_sell: null }]);
    const [selectedProviderCategory, setSelectedProviderCategory] = useState('');
    const [providerCategories, setProviderCategories] = useState([]);
    const [sortConfigs, setSortConfigs] = useState(() => {
        const savedSortConfigs = Cookies.get('promotionalBundlesSortConfigs');
        return savedSortConfigs ? JSON.parse(savedSortConfigs) : [
            { key: 'bundle_id', direction: 'asc' },
            { key: '', direction: 'asc' },
            { key: '', direction: 'asc' }
        ];
    });
    const navigate = useNavigate();
    const [expandedDescriptions, setExpandedDescriptions] = useState({});
    const [expandedProviderCategories, setExpandedProviderCategories] = useState(() => {
        const savedState = getCookie('expandedProviderCategories');
        return savedState || {};
    });
    const sortOptions = [
        { value: '', label: 'None' },
        { value: 'bundle_id', label: 'Bundle ID' },
        { value: 'package_name', label: 'Package Name' },
        { value: 'prov_cat_name', label: 'Provider Category' },
        { value: 'bundle_name', label: 'Bundle Name' },
        { value: 'bundle_description', label: 'Description' },
        { value: 'package_price', label: 'Package Price' },
        { value: 'installation_price', label: 'Installation Price' },
        { value: 'bundle_priority', label: 'Priority' },
        { value: 'active_from', label: 'Active From' },
        { value: 'active_until', label: 'Active Until' },
        { value: 'status', label: 'Status' }
    ];

    useEffect(() => {
        fetchPromotionalBundles();
        fetchPackages();
        fetchPromotionCategories();
        fetchValueAddedProducts();
        fetchProviderCategories();
    }, []);

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                // First fetch all the supporting data
                await Promise.all([
                    fetchPackages(),
                    fetchProviderCategories(),
                    fetchValueAddedProducts(),
                    fetchPromotionCategories()
                ]);

                // Then fetch and process the bundles
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotional-bundles`);
                const bundles = response.data;
                
                if (bundles && bundles.length > 0) {
                    const grouped = groupBundlesByPromotionCategory(bundles);
                    const initialGroupedBundles = {};
                    
                    Object.entries(grouped).forEach(([categoryName, category]) => {
                        initialGroupedBundles[categoryName] = {
                            ...category,
                            providerCategories: Object.fromEntries(
                                Object.entries(category.providerCategories).map(([provCatName, provCat]) => [
                                    provCatName,
                                    {
                                        ...provCat,
                                        bundles: applySorting([...provCat.bundles], sortConfigs)
                                    }
                                ])
                            )
                        };
                    });
                    setPromotionalBundles(flattenGroupedBundles(initialGroupedBundles));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchAllData();
    }, []); // Empty dependency array for initial load only

    useEffect(() => {
        const storedBundleData = localStorage.getItem('createPromotionalBundle');
        if (storedBundleData && packages.length > 0) {
            const data = JSON.parse(storedBundleData);
            
            // Set the provider category first
            setSelectedProviderCategory(data.prov_cat_id.toString());
            
            // Find the package details
            const selectedPackage = packages.find(pkg => pkg.package_id === data.package_id);
            if (selectedPackage) {
                setBundleData({
                    promotion_category_id: '',
                    package_id: data.package_id.toString(),
                    bundle_name: data.bundle_data.bundle_name,
                    bundle_description: data.bundle_data.bundle_description,
                    package_price: data.bundle_data.package_price,
                    installation_price: data.bundle_data.installation_price,
                    bundle_priority: data.bundle_data.bundle_priority,
                    active_from: '',
                    active_until: '',
                    status: 'active'
                });
                
                setSelectedPackageDetails(selectedPackage);
                
                // Set value added products if they exist
                if (data.bundle_data.value_added_products && data.bundle_data.value_added_products.length > 0) {
                    setValueAddedProductsData(data.bundle_data.value_added_products);
                } else {
                    setValueAddedProductsData([{ vap_id: '', quantity: 1, price: 0, vap_cost: null, vap_sell: null }]);
                }
                
                if (data.showModal) {
                    setShowModal(true);
                }
            }
            
            localStorage.removeItem('createPromotionalBundle');
        }
    }, [packages]);

    useEffect(() => {
        // Save state to cookie whenever it changes
        setCookie('expandedProviderCategories', expandedProviderCategories);
        
        // Cleanup old cookies when component unmounts
        return () => {
            const savedState = getCookie('expandedProviderCategories');
            if (savedState) {
                // Remove any categories that no longer exist
                const currentCategories = Object.keys(expandedProviderCategories);
                const cleanedState = {};
                currentCategories.forEach(key => {
                    if (savedState[key] !== undefined) {
                        cleanedState[key] = savedState[key];
                    }
                });
                setCookie('expandedProviderCategories', cleanedState);
            }
        };
    }, [expandedProviderCategories]);

    useEffect(() => {
        if (promotionalBundles.length > 0) {
            const newGroupedBundles = {};
            Object.entries(groupBundlesByPromotionCategory()).forEach(([categoryName, category]) => {
                newGroupedBundles[categoryName] = {
                    ...category,
                    providerCategories: Object.fromEntries(
                        Object.entries(category.providerCategories).map(([provCatName, provCat]) => [
                            provCatName,
                            {
                                ...provCat,
                                bundles: applySorting([...provCat.bundles], sortConfigs)
                            }
                        ])
                    )
                };
            });
            setPromotionalBundles(flattenGroupedBundles(newGroupedBundles));
        }
    }, [promotionalBundles.length, sortConfigs]);

    const applySorting = (bundles, configs) => {
        return bundles.sort((a, b) => {
            for (const config of configs) {
                if (!config.key) continue;
                
                let comparison = 0;
                
                // Handle numeric values
                if (['bundle_id', 'package_price', 'installation_price', 'bundle_priority'].includes(config.key)) {
                    comparison = config.direction === 'asc' 
                        ? parseFloat(a[config.key]) - parseFloat(b[config.key])
                        : parseFloat(b[config.key]) - parseFloat(a[config.key]);
                }
                // Handle dates
                else if (['active_from', 'active_until'].includes(config.key)) {
                    const dateA = new Date(a[config.key] || '');
                    const dateB = new Date(b[config.key] || '');
                    comparison = config.direction === 'asc' ? dateA - dateB : dateB - dateA;
                }
                // Handle package name
                else if (config.key === 'package_name') {
                    const packageA = a.Package?.package_name || '';
                    const packageB = b.Package?.package_name || '';
                    comparison = config.direction === 'asc' 
                        ? packageA.localeCompare(packageB)
                        : packageB.localeCompare(packageA);
                }
                // Handle provider category name
                else if (config.key === 'prov_cat_name') {
                    const provCatA = a.Package?.ProviderCategory?.prov_cat_name || '';
                    const provCatB = b.Package?.ProviderCategory?.prov_cat_name || '';
                    comparison = config.direction === 'asc'
                        ? provCatA.localeCompare(provCatB)
                        : provCatB.localeCompare(provCatA);
                }
                // Default string comparison
                else {
                    const valA = a[config.key] || '';
                    const valB = b[config.key] || '';
                    comparison = config.direction === 'asc'
                        ? valA.localeCompare(valB)
                        : valB.localeCompare(valA);
                }
                
                if (comparison !== 0) return comparison;
            }
            return 0;
        });
    };

    const sortPromotionalBundles = (key) => {
        let direction = 'asc';
        if (sortConfigs.key === key && sortConfigs.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfigs({ key, direction });

        // Create a new copy of the bundles array
        const newBundles = promotionalBundles.map(bundle => ({...bundle}));
        
        // Group bundles by promotion category
        const grouped = {};
        newBundles.forEach(bundle => {
            const categoryName = bundle.PromotionCategory?.promotion_category_name || 'Uncategorized';
            if (!grouped[categoryName]) {
                grouped[categoryName] = [];
            }
            grouped[categoryName].push(bundle);
        });

        // Sort bundles within each category
        Object.keys(grouped).forEach(categoryName => {
            grouped[categoryName].sort((a, b) => {
                // Handle numeric values
                if (['promotional_bundle_id', 'package_price', 'installation_price', 'bundle_priority'].includes(key)) {
                    return direction === 'asc' ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key]);
                }
                // Handle dates
                if (['active_from', 'active_until'].includes(key)) {
                    const dateA = new Date(a[key] || '');
                    const dateB = new Date(b[key] || '');
                    return direction === 'asc' ? dateA - dateB : dateB - dateA;
                }
                // Handle package name
                if (key === 'package_name') {
                    const packageA = a.Package?.package_name || '';
                    const packageB = b.Package?.package_name || '';
                    return direction === 'asc' ? packageA.localeCompare(packageB) : packageB.localeCompare(packageA);
                }
                // Handle provider category name
                if (key === 'prov_cat_name') {
                    const provCatA = a.Package?.ProviderCategory?.prov_cat_name || '';
                    const provCatB = b.Package?.ProviderCategory?.prov_cat_name || '';
                    return direction === 'asc' ? provCatA.localeCompare(provCatB) : provCatB.localeCompare(provCatA);
                }
                // Default string comparison
                if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
                if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
                return 0;
            });
        });

        // Flatten the grouped bundles back into a single array while maintaining category grouping
        const sortedBundles = [];
        Object.values(grouped).forEach(bundles => {
            sortedBundles.push(...bundles);
        });

        setPromotionalBundles(sortedBundles);
    };

    const fetchPromotionalBundles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotional-bundles`);
            const bundles = response.data;
            
            // Sort only within categories
            const grouped = {};
            bundles.forEach(bundle => {
                const categoryName = bundle.PromotionCategory?.promotion_category_name || 'Uncategorized';
                if (!grouped[categoryName]) {
                    grouped[categoryName] = [];
                }
                grouped[categoryName].push(bundle);
            });

            // Apply initial sorting within each category
            Object.keys(grouped).forEach(categoryName => {
                grouped[categoryName].sort((a, b) => a.bundle_priority - b.bundle_priority);
            });

            // Flatten while maintaining category grouping
            const sortedBundles = [];
            Object.values(grouped).forEach(categoryBundles => {
                sortedBundles.push(...categoryBundles);
            });

            setPromotionalBundles(sortedBundles);
        } catch (error) {
            console.error("Error fetching promotional bundles:", error);
        }
    };

    const fetchPackages = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/packages`);
            setPackages(response.data);
        } catch (error) {
            console.error("Error fetching packages:", error);
        }
    };

    const fetchPromotionCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotion-categories`);
            setPromotionCategories(response.data);
        } catch (error) {
            console.error("Error fetching promotion categories:", error);
        }
    };

    const fetchValueAddedProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/value-added-products`);
            console.log("Fetched VAPs:", response.data);
            setValueAddedProducts(response.data);
        } catch (error) {
            console.error("Error fetching value-added products:", error);
        }
    };

    const fetchProviderCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider-categories`);
            setProviderCategories(response.data);
        } catch (error) {
            console.error("Error fetching provider categories:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBundleData({ ...bundleData, [name]: value });
    };

    const handlePackageChange = (e) => {
        const selectedPackageId = e.target.value;
        const selectedPackage = packages.find(pkg => pkg.package_id.toString() === selectedPackageId);
        if (selectedPackage) {
            setSelectedPackageDetails(selectedPackage);
            setBundleData(prevData => ({
                ...prevData,
                package_id: selectedPackageId,
                package_price: selectedPackage.sell_price_incl,
                installation_price: selectedPackage.installation,
                bundle_name: selectedPackage.package_name,
                bundle_description: selectedPackage.package_description || ''
            }));
        } else {
            setSelectedPackageDetails(null);
            setBundleData(prevData => ({
                ...prevData,
                package_id: '',
                package_price: '',
                bundle_name: '',
                installation_price: '',
                bundle_description: ''
            }));
        }
    };

    const handleVAPChange = (index, vapId) => {
        const selectedVAP = valueAddedProducts.find(vap => vap.vap_id.toString() === vapId);
        setValueAddedProductsData(prevVAPs => {
            const updatedVAPs = [...prevVAPs];
            updatedVAPs[index] = {
                ...updatedVAPs[index],
                vap_id: vapId,
                vap_cost: selectedVAP ? selectedVAP.vap_cost : null,
                vap_sell: selectedVAP ? selectedVAP.vap_sell : null,
                price: selectedVAP ? selectedVAP.vap_sell : 0
            };
            return updatedVAPs;
        });
    };

    const handleQuantityChange = (index, quantity) => {
        const updatedVAPs = [...valueAddedProductsData];
        updatedVAPs[index].quantity = quantity;
        setValueAddedProductsData(updatedVAPs);
    };

    const addNewVAP = () => {
        setValueAddedProductsData([...valueAddedProductsData, { vap_id: '', quantity: 1, price: 0, vap_cost: null, vap_sell: null }]);
    };

    const removeVAP = (index) => {
        const updatedVAPs = valueAddedProductsData.filter((_, i) => i !== index);
        setValueAddedProductsData(updatedVAPs);
    };

    const addOrUpdateBundle = async (e) => {
        e.preventDefault();
        
        // Create a copy of bundleData to modify dates
        const adjustedBundleData = {
            ...bundleData
        };

        // Adjust active_from if it exists
        if (adjustedBundleData.active_from) {
            const fromDate = new Date(adjustedBundleData.active_from);
            fromDate.setHours(fromDate.getHours() );
            adjustedBundleData.active_from = fromDate.toISOString().split('.')[0];
        }

        // Adjust active_until if it exists
        if (adjustedBundleData.active_until) {
            const untilDate = new Date(adjustedBundleData.active_until);
            untilDate.setHours(untilDate.getHours() );
            adjustedBundleData.active_until = untilDate.toISOString().split('.')[0];
        }

        const bundlePayload = {
            ...adjustedBundleData,
            value_added_products: valueAddedProductsData
                .filter(vap => vap.vap_id && vap.quantity > 0)
                .map(vap => ({
                    vap_id: vap.vap_id,
                    quantity: vap.quantity,
                    price: vap.price
                }))
        };

        console.log('Bundle Payload:', JSON.stringify(bundlePayload, null, 2));

        try {
            let response;
            if (editingBundleId) {
                console.log(`Updating bundle with ID: ${editingBundleId}`);
                response = await axios.put(`${process.env.REACT_APP_API_URL}/promotional-bundles/${editingBundleId}`, bundlePayload);
            } else {
                console.log('Creating new bundle');
                response = await axios.post(`${process.env.REACT_APP_API_URL}/promotional-bundles`, bundlePayload);
            }
            console.log('Server response:', JSON.stringify(response.data, null, 2));
            fetchPromotionalBundles();
            resetForm();
        } catch (error) {
            console.error("Error saving promotional bundle:", error.response ? JSON.stringify(error.response.data, null, 2) : error);
        }
    };

    const startEditing = (bundle) => {
        const associatedPackage = packages.find(pkg => pkg.package_id === bundle.package_id);
        const associatedVAPs = bundle.ValueAddedProducts ? bundle.ValueAddedProducts.map(vapData => ({
            vap_id: vapData.vap_id.toString(),
            quantity: vapData.BundleValueAddedProduct?.quantity || 1,
            price: vapData.BundleValueAddedProduct?.price || 0,
            vap_cost: vapData.vap_cost,
            vap_sell: vapData.vap_sell
        })) : [];

        setBundleData({
            promotion_category_id: bundle.promotion_category_id.toString(),
            package_id: bundle.package_id.toString(),
            bundle_name: bundle.bundle_name || '',
            bundle_description: bundle.bundle_description,
            package_price: bundle.package_price,
            installation_price: bundle.installation_price,
            bundle_priority: bundle.bundle_priority,
            active_from: bundle.active_from ? (() => {
                const date = new Date(bundle.active_from);
                date.setHours(date.getHours() + 2); // Adjust to GMT+2
                return date.toISOString().split('T')[0];
            })() : '',
            active_until: bundle.active_until ? (() => {
                const date = new Date(bundle.active_until);
                date.setHours(date.getHours() + 2); // Adjust to GMT+2
                return date.toISOString().split('T')[0];
            })() : '',
            status: bundle.status,
        });

        setValueAddedProductsData(associatedVAPs.length > 0 ? associatedVAPs : [{ vap_id: '', quantity: 1, price: 0, vap_cost: null, vap_sell: null }]);
        setEditingBundleId(bundle.bundle_id);
        setSelectedProviderCategory(associatedPackage ? associatedPackage.prov_cat_id.toString() : '');
        setSelectedPackageDetails(associatedPackage);
        setShowModal(true);
    };

    const deleteBundle = async (id) => {
        if (window.confirm("Are you sure you want to delete this promotional bundle?")) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/promotional-bundles/${id}`);
                fetchPromotionalBundles();
            } catch (error) {
                console.error("Error deleting promotional bundle:", error);
            }
        }
    };

    const resetForm = () => {
        setBundleData({
            promotion_category_id: '',
            package_id: '',
            bundle_name: '',
            bundle_description: '',
            package_price: '',
            installation_price: '',
            bundle_priority: '',
            active_from: '',
            active_until: '',
            status: 'active',
        });
        setValueAddedProductsData([{ vap_id: '', quantity: 1, price: 0, vap_cost: null, vap_sell: null }]);
        setEditingBundleId(null);
        setShowModal(false);
        setSelectedPackageDetails(null);
        setSelectedProviderCategory('');
    };

    const handleProviderCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;
        setSelectedProviderCategory(selectedCategoryId);
        setBundleData({ ...bundleData, package_id: '' });
        setSelectedPackageDetails(null);
        
        // Filter packages based on the selected provider category
        const filteredPackages = packages.filter(pkg => pkg.prov_cat_id === parseInt(selectedCategoryId));
        setPackages(filteredPackages);
    };

    const setDefaultBundle = async (bundleId) => {
        try {
            if (window.confirm("Set this as the default bundle? This will remove default status from any other bundle in this category.")) {
                await axios.put(`${process.env.REACT_APP_API_URL}/promotional-bundles/${bundleId}/set-default`);
                fetchPromotionalBundles(); // Refresh the list
            }
        } catch (error) {
            console.error("Error setting default bundle:", error);
            alert("An error occurred while setting the default bundle.");
        }
    };

    const groupBundlesByPromotionCategory = (bundles = promotionalBundles) => {
        if (!bundles || bundles.length === 0) return {};
        
        const grouped = {};
        bundles.forEach(bundle => {
            const categoryName = bundle.PromotionCategory?.promotion_category_name || 'Uncategorized';
            const providerCategoryName = bundle.Package?.ProviderCategory?.prov_cat_name || 'Uncategorized';
            
            if (!grouped[categoryName]) {
                grouped[categoryName] = {
                    providerCategories: {}
                };
            }
            
            if (!grouped[categoryName].providerCategories[providerCategoryName]) {
                grouped[categoryName].providerCategories[providerCategoryName] = {
                    bundles: []
                };
            }
            
            grouped[categoryName].providerCategories[providerCategoryName].bundles.push(bundle);
        });
        
        return grouped;
    };

    const copyBundle = (bundle) => {
        const associatedPackage = packages.find(pkg => pkg.package_id === bundle.package_id);
        const associatedVAPs = bundle.ValueAddedProducts ? bundle.ValueAddedProducts.map(vapData => ({
            vap_id: vapData.vap_id.toString(),
            quantity: vapData.BundleValueAddedProduct?.quantity || 1,
            price: vapData.BundleValueAddedProduct?.price || 0,
            vap_cost: vapData.vap_cost,
            vap_sell: vapData.vap_sell
        })) : [];

        // Set provider category and handle package change
        setSelectedProviderCategory(associatedPackage ? associatedPackage.prov_cat_id.toString() : '');
        
        setBundleData({
            promotion_category_id: bundle.promotion_category_id.toString(),
            package_id: bundle.package_id.toString(),
            bundle_name: bundle.bundle_name || '',
            bundle_description: bundle.bundle_description,
            package_price: bundle.package_price,
            installation_price: bundle.installation_price,
            bundle_priority: bundle.bundle_priority,
            active_from: bundle.active_from ? (() => {
                const date = new Date(bundle.active_from);
                date.setHours(date.getHours() + 2); // Adjust to GMT+2
                return date.toISOString().split('T')[0];
            })() : '',
            active_until: bundle.active_until ? (() => {
                const date = new Date(bundle.active_until);
                date.setHours(date.getHours() + 2); // Adjust to GMT+2
                return date.toISOString().split('T')[0];
            })() : '',
            status: 'active'  // Default to active for new copy
        });

        setValueAddedProductsData(associatedVAPs.length > 0 ? associatedVAPs : [{ vap_id: '', quantity: 1, price: 0, vap_cost: null, vap_sell: null }]);
        setEditingBundleId(null);  // Ensure we're creating a new bundle
        setSelectedPackageDetails(associatedPackage);
        setShowModal(true);
    };

    const truncateDescription = (text, id) => {
        if (!text) return '';
        if (expandedDescriptions[id]) return text;
        
        return text.length > 50 ? (
            <span>
                {text.substring(0, 50)}...
                <a 
                    href="#" 
                    onClick={(e) => {
                        e.preventDefault();
                        setExpandedDescriptions(prev => ({
                            ...prev,
                            [id]: true
                        }));
                    }}
                    className="text-primary ms-1"
                >
                    more
                </a>
            </span>
        ) : text;
    };

    const toggleProviderCategory = (categoryKey) => {
        setExpandedProviderCategories(prev => {
            const newState = {
                ...prev,
                [categoryKey]: !prev[categoryKey]
            };
            setCookie('expandedProviderCategories', newState);
            return newState;
        });
    };

    const flattenGroupedBundles = (groupedBundles) => {
        const flattened = [];
        Object.values(groupedBundles).forEach(category => {
            Object.values(category.providerCategories).forEach(provCat => {
                flattened.push(...provCat.bundles);
            });
        });
        return flattened;
    };

    const handleSortDirectionChange = (index) => {
        const newConfigs = [...sortConfigs];
        newConfigs[index] = {
            ...newConfigs[index],
            direction: newConfigs[index].direction === 'asc' ? 'desc' : 'asc'
        };
        setSortConfigs(newConfigs);
        
        const newGroupedBundles = {};
        Object.entries(groupBundlesByPromotionCategory()).forEach(([categoryName, category]) => {
            newGroupedBundles[categoryName] = {
                ...category,
                providerCategories: Object.fromEntries(
                    Object.entries(category.providerCategories).map(([provCatName, provCat]) => [
                        provCatName,
                        {
                            ...provCat,
                            bundles: applySorting([...provCat.bundles], newConfigs)
                        }
                    ])
                )
            };
        });
        setPromotionalBundles(flattenGroupedBundles(newGroupedBundles));
        Cookies.set('promotionalBundlesSortConfigs', JSON.stringify(newConfigs), { expires: 30 });
    };

    return (
        <div className="container mt-4">
            <h1>Promotional Bundles</h1>
            <button className="btn btn-primary mb-3" onClick={() => { resetForm(); setShowModal(true); }}>
                Add Promotional Bundle
            </button>

            <div className="sort-controls mb-4">
                <div className="row">
                    {[0, 1, 2].map((index) => (
                        <div key={index} className="col-md-4">
                            <div className="d-flex align-items-center">
                                <select 
                                    className="form-control me-2"
                                    value={sortConfigs[index].key}
                                    onChange={(e) => {
                                        const newConfigs = [...sortConfigs];
                                        newConfigs[index].key = e.target.value;
                                        setSortConfigs(newConfigs);
                                        const newGroupedBundles = {};
                                        Object.entries(groupBundlesByPromotionCategory()).forEach(([categoryName, category]) => {
                                            newGroupedBundles[categoryName] = {
                                                ...category,
                                                providerCategories: Object.fromEntries(
                                                    Object.entries(category.providerCategories).map(([provCatName, provCat]) => [
                                                        provCatName,
                                                        {
                                                            ...provCat,
                                                            bundles: applySorting([...provCat.bundles], newConfigs)
                                                        }
                                                    ])
                                                )
                                            };
                                        });
                                        setPromotionalBundles(flattenGroupedBundles(newGroupedBundles));
                                        Cookies.set('promotionalBundlesSortConfigs', JSON.stringify(newConfigs), { expires: 30 });
                                    }}
                                >
                                    {sortOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                <button 
                                    className="btn btn-outline-secondary"
                                    onClick={() => handleSortDirectionChange(index)}
                                >
                                    {sortConfigs[index].direction === 'asc' ? '↑' : '↓'}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            
            {Object.entries(groupBundlesByPromotionCategory()).map(([promotionCategoryName, promotionCategory]) => {
                const totalPromotions = Object.values(promotionCategory.providerCategories)
                    .reduce((sum, provCat) => sum + provCat.bundles.length, 0);
                    
                return (
                    <div key={promotionCategoryName} className="mb-5">
                        <h2 className="promotion-category-title">
                            {promotionCategoryName} ({totalPromotions})
                        </h2>
                        
                        {Object.entries(promotionCategory.providerCategories).map(([providerCategoryName, providerCategory]) => {
                            const categoryKey = `${promotionCategoryName}-${providerCategoryName}`;
                            const isExpanded = expandedProviderCategories[categoryKey] !== false; // Default to expanded
                            
                            return (
                                <div key={categoryKey} className="mb-4">
                                    <div 
                                        className="provider-category-header d-flex align-items-center"
                                        onClick={() => toggleProviderCategory(categoryKey)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <h3 className="provider-category-title h4 mt-4 mb-3 me-2">
                                            <i className={`fas fa-chevron-${isExpanded ? 'down' : 'right'} me-2`}></i>
                                            {providerCategoryName} ({providerCategory.bundles.length})
                                        </h3>
                                    </div>
                                    
                                    <div className={`collapse ${isExpanded ? 'show' : ''}`}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Bundle ID</th>
                                                    <th>Package Name</th>
                                                    <th>Provider Category</th>
                                                    <th>Bundle Name</th>
                                                    <th>Description</th>
                                                    <th>Package Price</th>
                                                    <th>Installation Price</th>
                                                    <th>Priority</th>
                                                    <th>Active From</th>
                                                    <th>Active Until</th>
                                                    <th>Status</th>
                                                    <th>Value Added Products</th>
                                                    <th>Default</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {providerCategory.bundles.map(bundle => (
                                                    <tr key={bundle.bundle_id}>
                                                        <td>{bundle.bundle_id}</td>
                                                        <td>{bundle.Package?.package_name || 'N/A'}</td>
                                                        <td>{bundle.Package?.ProviderCategory?.prov_cat_name || 'N/A'}</td>
                                                        <td>{bundle.bundle_name || 'N/A'}</td>
                                                        <td>
                                                            {truncateDescription(bundle.bundle_description, bundle.bundle_id)}
                                                            {expandedDescriptions[bundle.bundle_id] && (
                                                                <a 
                                                                    href="#" 
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setExpandedDescriptions(prev => ({
                                                                            ...prev,
                                                                            [bundle.bundle_id]: false
                                                                        }));
                                                                    }}
                                                                    className="text-primary ms-1"
                                                                >
                                                                    less
                                                                </a>
                                                            )}
                                                        </td>
                                                        <td>R{bundle.package_price}</td>
                                                        <td>R{bundle.installation_price}</td>
                                                        <td>{bundle.bundle_priority}</td>
                                                        <td>{formatDateToGMTPlus2(bundle.active_from)}</td>
                                                        <td>{formatDateToGMTPlus2(bundle.active_until)}</td>
                                                        <td>{bundle.status}</td>
                                                        
                                                        <td>
                                                            {bundle.ValueAddedProducts && bundle.ValueAddedProducts.length > 0 ? (
                                                                <ul>
                                                                    {bundle.ValueAddedProducts.map(vap => (
                                                                        <li key={vap.vap_id}>
                                                                            {vap.vap_name} - 
                                                                            Quantity: {vap.BundleValueAddedProduct?.quantity || 'N/A'}, 
                                                                            Price: R{vap.BundleValueAddedProduct?.price || 'N/A'}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            ) : (
                                                                'No VAPs'
                                                            )}
                                                        </td>
                                                        
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <button 
                                                                    className={`btn btn-sm ${bundle.promo_bundle_default ? 'btn-success' : 'btn-outline-success'} me-2`}
                                                                    onClick={() => setDefaultBundle(bundle.bundle_id)}
                                                                    title={bundle.promo_bundle_default ? 'Default Bundle' : 'Set as Default'}
                                                                >
                                                                    <i className="fas fa-star"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <button className="btn btn-warning btn-sm me-2" onClick={() => startEditing(bundle)}>Edit</button>
                                                                <button className="btn btn-danger btn-sm me-2" onClick={() => deleteBundle(bundle.bundle_id)}>Delete</button>
                                                                <button className="btn btn-info btn-sm me-2" onClick={() => copyBundle(bundle)}>Copy</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
            
            {/* Modal for Adding/Editing Promotional Bundle */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{editingBundleId ? 'Edit Promotional Bundle' : 'Add Promotional Bundle'}</h5>
                            <button type="button" className="close" onClick={resetForm}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={addOrUpdateBundle}>
                                <div className="form-group mb-3">
                                    <label>Promotion Category*</label>
                                    <select className="form-control" name="promotion_category_id" value={bundleData.promotion_category_id} onChange={handleInputChange} required>
                                        <option value="">Select Promotion Category</option>
                                        {promotionCategories.map(category => (
                                            <option key={category.promotion_category_id} value={category.promotion_category_id}>
                                                {category.promotion_category_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Provider Category*</label>
                                    <select className="form-control" name="provider_category" value={selectedProviderCategory} onChange={handleProviderCategoryChange} required>
                                        <option value="">Select Provider Category</option>
                                        {providerCategories.map(category => (
                                            <option key={category.prov_cat_id} value={category.prov_cat_id}>
                                                {category.prov_cat_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Package*</label>
                                    <select className="form-control" name="package_id" value={bundleData.package_id} onChange={handlePackageChange} required>
                                        <option value="">Select Package</option>
                                        {packages
                                            .filter(pkg => pkg.prov_cat_id === parseInt(selectedProviderCategory))
                                            .map(pkg => (
                                                <option key={pkg.package_id} value={pkg.package_id}>
                                                    {pkg.package_name} - Term: {pkg.term}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Bundle Name*</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="bundle_name" 
                                        value={bundleData.bundle_name} 
                                        onChange={handleInputChange} 
                                        required 
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Bundle Description*</label>
                                    <textarea className="form-control" name="bundle_description" value={bundleData.bundle_description} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Package Price* (Selected Package Cost Price: R{selectedPackageDetails ? selectedPackageDetails.cost_price_incl : 'N/A'}, Sell Price: R{selectedPackageDetails ? selectedPackageDetails.sell_price_incl : 'N/A'})</label>
                                    <input type="number" className="form-control" name="package_price" value={bundleData.package_price} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Installation Price* (Selected Package Installation: R{selectedPackageDetails ? selectedPackageDetails.installation : 'N/A'})</label>
                                    <input type="number" className="form-control" name="installation_price" value={bundleData.installation_price} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Bundle Priority*</label>
                                    <input type="number" className="form-control" name="bundle_priority" value={bundleData.bundle_priority} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Active From</label>
                                    <input type="date" className="form-control" name="active_from" value={bundleData.active_from} onChange={handleInputChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Active Until</label>
                                    <input type="date" className="form-control" name="active_until" value={bundleData.active_until} onChange={handleInputChange} />
                                </div>
                              
                                <div className="form-group mb-3">
                                    <label>Value Added Products</label>
                                    <div className="vap-bundle">
                                        {valueAddedProductsData.map((vap, index) => (
                                            <div key={index} className="mb-3">
                                                <select
                                                    className="form-control mb-2"
                                                    value={vap.vap_id}
                                                    onChange={(e) => handleVAPChange(index, e.target.value)}
                                                    required
                                                >
                                                    <option value="">Select VAP</option>
                                                    {valueAddedProducts
                                                        .filter(product => !valueAddedProductsData.some((v, i) => i !== index && v.vap_id === product.vap_id))
                                                        .map(product => (
                                                            <option key={product.vap_id} value={product.vap_id}>
                                                                {product.vap_name}
                                                            </option>
                                                        ))}
                                                </select>
                                                <label>Quantity*</label>
                                                <input
                                                    type="number"
                                                    className="form-control mb-2"
                                                    value={vap.quantity}
                                                    onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                                                    min="1"
                                                    required
                                                />
                                                <label>
                                                    VAP Price* 
                                                    {vap.vap_id && (
                                                        <span>
                                                            (Cost: R{vap.vap_cost || 'N/A'}, 
                                                            Sell: R{vap.vap_sell || 'N/A'})
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control mb-2"
                                                    value={vap.price}
                                                    onChange={(e) => {
                                                        const updatedVAPs = [...valueAddedProductsData];
                                                        updatedVAPs[index].price = parseFloat(e.target.value);
                                                        setValueAddedProductsData(updatedVAPs);
                                                    }}
                                                    min="0"
                                                    step="0.01"
                                                    required
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => removeVAP(index)}
                                                >
                                                    Remove VAP
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-primary bundle-vap-btn"
                                        onClick={addNewVAP}
                                    >
                                        Add Another VAP
                                    </button>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Status*</label>
                                    <select className="form-control" name="status" value={bundleData.status} onChange={handleInputChange} required>
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="deleted">Deleted</option>
                                    </select>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={resetForm}>Close</button>
                                    <button type="submit" className="btn btn-primary">
                                        {editingBundleId ? 'Update Promotional Bundle' : 'Add Promotional Bundle'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromotionalBundles;
