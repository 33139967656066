import React from 'react';
import TeamMembers from '../components/TeamMembers';
import 'bootstrap/dist/css/bootstrap.min.css';

const TeamMembersPage = () => {
    return (
        <div className="container">
            
            <TeamMembers />
        </div>
    );
};

export default TeamMembersPage;