import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ImageUpload from './ImageUpload';
import 'bootstrap/dist/css/bootstrap.min.css';

const TechnologyClasses = () => {
    const [technologyClasses, setTechnologyClasses] = useState([]);
    const [technologyClassData, setTechnologyClassData] = useState({
        tech_class_name: '',
        image_url: '',
        tech_class_priority: '',
        status: 'active',
        tech_class_user_type: '',
        tech_class_description: '',
        tech_class_banner_image: '',
        tech_class_coverage: 'false',
    });
    const [editingId, setEditingId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [initialImageUrl, setInitialImageUrl] = useState(null);
    const [imageUploadKey, setImageUploadKey] = useState(0);
    const imageUploadRef = useRef();
    const [sortConfig, setSortConfig] = useState({
        key: 'tech_class_id',
        direction: 'asc'
    });

    useEffect(() => {
        fetchTechnologyClasses();
    }, []);

    const fetchTechnologyClasses = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/technology-classes`);
            const sortedClasses = applySorting(response.data);
            setTechnologyClasses(sortedClasses);
        } catch (error) {
            console.error("Error fetching technology classes:", error);
        }
    };

    const fetchProviderCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider-categories`);
            return response.data;
        } catch (error) {
            console.error("Error fetching provider categories:", error);
            return [];
        }
    };

    const openModal = (technologyClass = null) => {
        if (technologyClass) {
            setTechnologyClassData({
                tech_class_name: technologyClass.tech_class_name,
                image_url: technologyClass.image_url,
                tech_class_priority: technologyClass.tech_class_priority,
                status: technologyClass.status,
                tech_class_user_type: technologyClass.tech_class_user_type,
                tech_class_description: technologyClass.tech_class_description,
                tech_class_banner_image: technologyClass.tech_class_banner_image,
                tech_class_coverage: technologyClass.tech_class_coverage,
            });
            setEditingId(technologyClass.tech_class_id);
            setInitialImageUrl(technologyClass.image_url);
        } else {
            resetForm(true);
        }
        setShowModal(true);
        if (imageUploadRef.current && imageUploadRef.current.resetImageUploadState) {
            imageUploadRef.current.resetImageUploadState();
        }
    };

    const addOrUpdateTechnologyClass = async (e) => {
        e.preventDefault();
        if (!technologyClassData.image_url) {
            alert('Please upload an image');
            return;
        }
        try {
            if (editingId) {
                await axios.put(`${process.env.REACT_APP_API_URL}/technology-classes/${editingId}`, technologyClassData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/technology-classes`, technologyClassData);
            }
            resetForm();
            fetchTechnologyClasses();
        } catch (error) {
            console.error("Error saving technology class:", error);
        }
    };

    const resetForm = (keepModalOpen = false) => {
        setTechnologyClassData({
            tech_class_name: '',
            image_url: '',
            tech_class_priority: '',
            status: 'active',
            tech_class_user_type: '',
            tech_class_description: '',
            tech_class_banner_image: '',
            tech_class_coverage: 'false',
        });
        setEditingId(null);
        setInitialImageUrl(null);
        setImageUploadKey(prevKey => prevKey + 1);
        if (!keepModalOpen) {
            setShowModal(false);
        }
        if (imageUploadRef.current && imageUploadRef.current.resetImageUploadState) {
            imageUploadRef.current.resetImageUploadState();
        }
    };

    const deleteTechnologyClass = async (id) => {
        try {
            const providerCategories = await fetchProviderCategories();
            const linkedProviderCategories = providerCategories.filter(category => category.tech_class_id === id);

            if (linkedProviderCategories.length > 0) {
                alert(`This technology class cannot be deleted as it is linked to ${linkedProviderCategories.length} provider categorie(s).`);
                return;
            }

            if (window.confirm("Are you sure you want to delete this technology class?")) {
                await axios.delete(`${process.env.REACT_APP_API_URL}/technology-classes/${id}`);
                fetchTechnologyClasses();
            }
        } catch (error) {
            console.error("Error deleting technology class:", error);
            alert("An error occurred while trying to delete the technology class.");
        }
    };

    const applySorting = (classesToSort) => {
        const { key, direction } = sortConfig;
        if (!key) return classesToSort;

        return [...classesToSort].sort((a, b) => {
            // Handle numeric values
            if (['tech_class_id', 'tech_class_priority'].includes(key)) {
                return direction === 'asc' ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key]);
            }
            // Handle boolean values
            if (key === 'tech_class_coverage') {
                const boolA = a[key] === 'true';
                const boolB = b[key] === 'true';
                return direction === 'asc' ? boolA - boolB : boolB - boolA;
            }
            // Default string comparison
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const sortTechnologyClasses = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setTechnologyClasses(applySorting([...technologyClasses]));
    };

    return (
        <div className="container mt-4">
            <h1>Technology Classes</h1>
            <button className="btn btn-primary mb-3" onClick={() => openModal()}>Add Technology Class</button>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => sortTechnologyClasses('tech_class_id')} style={{cursor: 'pointer'}}>
                            ID {sortConfig.key === 'tech_class_id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortTechnologyClasses('tech_class_name')} style={{cursor: 'pointer'}}>
                            Name {sortConfig.key === 'tech_class_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortTechnologyClasses('tech_class_user_type')} style={{cursor: 'pointer'}}>
                            User Type {sortConfig.key === 'tech_class_user_type' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th>Image</th>
                        <th>Banner Image</th>
                        <th onClick={() => sortTechnologyClasses('tech_class_description')} style={{cursor: 'pointer'}}>
                            Description {sortConfig.key === 'tech_class_description' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortTechnologyClasses('tech_class_coverage')} style={{cursor: 'pointer'}}>
                            Coverage {sortConfig.key === 'tech_class_coverage' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortTechnologyClasses('tech_class_priority')} style={{cursor: 'pointer'}}>
                            Priority {sortConfig.key === 'tech_class_priority' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortTechnologyClasses('status')} style={{cursor: 'pointer'}}>
                            Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {technologyClasses.map(techClass => (
                        <tr key={techClass.tech_class_id}>
                            <td>{techClass.tech_class_id}</td>
                            <td>{techClass.tech_class_name}</td>
                            <td>{techClass.tech_class_user_type}</td>
                            <td>
                                {techClass.image_url && (
                                    <img 
                                        src={process.env.REACT_APP_IMAGE_URL + '/' + techClass.image_url} 
                                        alt={techClass.tech_class_name} 
                                        style={{ width: '50px', height: '50px', objectFit: 'contain', borderRadius: '5px' }} 
                                    />
                                ) || <span className='table-warning'>No Image</span>}
                            </td>
                            <td>
                                {techClass.tech_class_banner_image && (
                                    <img 
                                        src={process.env.REACT_APP_IMAGE_URL + '/' + techClass.tech_class_banner_image} 
                                        alt={`${techClass.tech_class_name} Banner`} 
                                        style={{ width: '50px', height: '50px', objectFit: 'contain', borderRadius: '5px' }} 
                                    />
                                ) || <span className='table-warning'>No Banner</span>}
                            </td>
                            <td>{techClass.tech_class_description}</td>
                            <td>{techClass.tech_class_coverage}</td>
                            <td>{techClass.tech_class_priority}</td>
                            <td>{techClass.status}</td>
                            <td>
                                <button className="btn btn-warning btn-sm me-2" onClick={() => openModal(techClass)}>Edit</button>
                                <button className="btn btn-danger btn-sm" onClick={() => deleteTechnologyClass(techClass.tech_class_id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Adding/Editing Technology Class */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="technologyClassModalLabel" aria-hidden={!showModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="technologyClassModalLabel">{editingId ? 'Edit Technology Class' : 'Add Technology Class'}</h5>
                        </div>
                        <form onSubmit={addOrUpdateTechnologyClass} className="p-3">
                            <div className="form-group mb-3">
                                <label>Technology Class Name*</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    value={technologyClassData.tech_class_name} 
                                    onChange={(e) => setTechnologyClassData({ ...technologyClassData, tech_class_name: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>User Type*</label>
                                <select 
                                    className="form-control" 
                                    value={technologyClassData.tech_class_user_type} 
                                    onChange={(e) => setTechnologyClassData({ ...technologyClassData, tech_class_user_type: e.target.value })}
                                    required
                                >
                                    <option value="">Select User Type</option>
                                    <option value="home">Home</option>
                                    <option value="business">Business</option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>Icon Image (150x150)*</label>
                                <div className="form-group">
                                    {initialImageUrl && (
                                        <img src={process.env.REACT_APP_IMAGE_URL + '/' + initialImageUrl} alt="Current Image" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                                    )}
                                </div>
                            </div>
                            <ImageUpload 
                                ref={imageUploadRef}
                                onUploadSuccess={(url) => setTechnologyClassData({ ...technologyClassData, image_url: url })}
                                identifier="technology-class"
                                key={imageUploadKey}
                                category_id={2}
                                required
                            />
                            <div className="form-group mb-3">
                                <label>Priority*</label>
                                <input 
                                    type="number" 
                                    className="form-control" 
                                    value={technologyClassData.tech_class_priority} 
                                    onChange={(e) => setTechnologyClassData({ ...technologyClassData, tech_class_priority: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Status</label>
                                <select 
                                    className="form-control" 
                                    value={technologyClassData.status} 
                                    onChange={(e) => setTechnologyClassData({ ...technologyClassData, status: e.target.value })}
                                    required
                                >
                                    <option value="active">Active</option>
                                    <option value="disabled">Disabled</option>
                                    <option value="deleted">Deleted</option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>Description</label>
                                <textarea 
                                    className="form-control" 
                                    value={technologyClassData.tech_class_description} 
                                    onChange={(e) => setTechnologyClassData({ ...technologyClassData, tech_class_description: e.target.value })}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Banner Image (570x300)*</label>
                                <div className="form-group">
                                    {technologyClassData.tech_class_banner_image && (
                                        <img src={process.env.REACT_APP_IMAGE_URL + '/' + technologyClassData.tech_class_banner_image} alt="Current Banner Image" style={{ width: '200px', height: 'auto', objectFit: 'contain', marginBottom: '10px' }} />
                                    )}
                                </div>
                                <ImageUpload 
                                    onUploadSuccess={(url) => setTechnologyClassData({ ...technologyClassData, tech_class_banner_image: url })}
                                    identifier="technology-class-banner"
                                    category_id={2}
                                    key={`banner-${imageUploadKey}`}
                                    required
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Coverage</label>
                                <select 
                                    className="form-control" 
                                    value={technologyClassData.tech_class_coverage} 
                                    onChange={(e) => setTechnologyClassData({ ...technologyClassData, tech_class_coverage: e.target.value })}
                                >
                                    <option value="false">False</option>
                                    <option value="true">True</option>
                                </select>
                            </div>
                           
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => resetForm()}>Close</button>
                                <button type="submit" className="btn btn-primary">
                                    {editingId ? 'Update Technology Class' : 'Add Technology Class'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TechnologyClasses;
