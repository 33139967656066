import React from 'react';
import ValueAddedProducts from '../components/valueAddedProducts'; // Adjust the path as necessary
import 'bootstrap/dist/css/bootstrap.min.css';

const ValueAddedProductsPage = () => {
    return (
        <div className="container mt-4">
            
            <ValueAddedProducts />
        </div>
    );
};

export default ValueAddedProductsPage;