import React from 'react';
import Branches from '../components/Branches';
import 'bootstrap/dist/css/bootstrap.min.css';

const BranchesPage = () => {
    return (
        <div className="container">
            
            <Branches />
        </div>
    );
};

export default BranchesPage;