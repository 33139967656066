import React from 'react';
import Categories from '../components/Categories';
import 'bootstrap/dist/css/bootstrap.min.css';

const CategoriesPage = () => {
    return (
        <div className="container">
            
            <Categories />
        </div>
    );
};

export default CategoriesPage;