import React from 'react';
import PromotionalPackages from '../components/PromotionalPackages';

const PromotionalPackagesPage = () => {
    return (
        <div className="container ">
            
            <PromotionalPackages />
        </div>
    );
};

export default PromotionalPackagesPage;