// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Login.css */
body {
    background-color: #f8f9fa; /* Light background color */
}

.card {
    border-radius: 10px; /* Rounded corners for the card */
}

.card-title {
    font-weight: bold; /* Bold title */
}

.btn-primary {
    background-color: #007bff; /* Bootstrap primary color */
    border: none; /* Remove border */
}

.btn-primary:hover {
    background-color: #0056b3; /* Darker shade on hover */
}`, "",{"version":3,"sources":["webpack://./src/components/Login.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,yBAAyB,EAAE,2BAA2B;AAC1D;;AAEA;IACI,mBAAmB,EAAE,iCAAiC;AAC1D;;AAEA;IACI,iBAAiB,EAAE,eAAe;AACtC;;AAEA;IACI,yBAAyB,EAAE,4BAA4B;IACvD,YAAY,EAAE,kBAAkB;AACpC;;AAEA;IACI,yBAAyB,EAAE,0BAA0B;AACzD","sourcesContent":["/* Login.css */\r\nbody {\r\n    background-color: #f8f9fa; /* Light background color */\r\n}\r\n\r\n.card {\r\n    border-radius: 10px; /* Rounded corners for the card */\r\n}\r\n\r\n.card-title {\r\n    font-weight: bold; /* Bold title */\r\n}\r\n\r\n.btn-primary {\r\n    background-color: #007bff; /* Bootstrap primary color */\r\n    border: none; /* Remove border */\r\n}\r\n\r\n.btn-primary:hover {\r\n    background-color: #0056b3; /* Darker shade on hover */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
