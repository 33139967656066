import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ImageUpload from './ImageUpload';

const ProviderCategories = () => {
    const [providerCategories, setProviderCategories] = useState([]);
    const [categoryData, setCategoryData] = useState({
        provider_id: '',
        cat_id: '',
        tech_class_id: '',
        prov_cat_image_url: '',
        prov_cat_priority: '',
        prov_cat_name: '',
        prov_cat_description: '',
        status: 'active'
    });
    const [editingCategoryId, setEditingCategoryId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [providers, setProviders] = useState([]);
    const [categories, setCategories] = useState([]);
    const [technologyClasses, setTechnologyClasses] = useState([]);
    const [initialImageUrl, setInitialImageUrl] = useState(null);
    const [imageUploadKey, setImageUploadKey] = useState(0);
    const imageUploadRef = useRef();
    const [sortConfig, setSortConfig] = useState({
        key: 'prov_cat_id',
        direction: 'asc'
    });
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    useEffect(() => {
        fetchProviderCategories();
        fetchProviders();
        fetchCategories();
        fetchTechnologyClasses();
    }, []);

    const fetchProviderCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider-categories`);
            const sortedCategories = applySorting(response.data);
            setProviderCategories(sortedCategories);
        } catch (error) {
            console.error("Error fetching provider categories:", error);
        }
    };

    const fetchProviders = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/providers`);
            setProviders(response.data);
        } catch (error) {
            console.error("Error fetching providers:", error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const fetchTechnologyClasses = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/technology-classes`);
            setTechnologyClasses(response.data);
        } catch (error) {
            console.error("Error fetching technology classes:", error);
        }
    };

    const openModal = (providerCategory = null) => {
        if (providerCategory) {
            setCategoryData({
                provider_id: providerCategory.provider_id,
                cat_id: providerCategory.cat_id,
                tech_class_id: providerCategory.tech_class_id,
                prov_cat_image_url: providerCategory.prov_cat_image_url,
                prov_cat_priority: providerCategory.prov_cat_priority,
                prov_cat_name: providerCategory.prov_cat_name,
                prov_cat_description: providerCategory.prov_cat_description,
                status: providerCategory.status
            });
            setEditingCategoryId(providerCategory.prov_cat_id);
            setInitialImageUrl(providerCategory.prov_cat_image_url);
        } else {
            resetForm(true);
        }
        setShowModal(true);
        if (imageUploadRef.current && imageUploadRef.current.resetImageUploadState) {
            imageUploadRef.current.resetImageUploadState();
        }
    };

    const addOrUpdateCategory = async (e) => {
        e.preventDefault();
        if (!categoryData.prov_cat_image_url) {
            alert('Please upload an image');
            return;
        }
        try {
            const dataToSend = {
                ...categoryData,
                prov_cat_priority: categoryData.prov_cat_priority !== '' ? Number(categoryData.prov_cat_priority) : 0
            };

            if (editingCategoryId) {
                await axios.put(`${process.env.REACT_APP_API_URL}/provider-categories/${editingCategoryId}`, dataToSend);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/provider-categories`, dataToSend);
            }
            resetForm();
            fetchProviderCategories();
        } catch (error) {
            console.error("Error saving provider category:", error);
        }
    };

    const resetForm = (keepModalOpen = false) => {
        setCategoryData({
            provider_id: '',
            cat_id: '',
            tech_class_id: '',
            prov_cat_image_url: '',
            prov_cat_priority: '',
            prov_cat_name: '',
            prov_cat_description: '',
            status: 'active'
        });
        setEditingCategoryId(null);
        setInitialImageUrl(null);
        setImageUploadKey(prevKey => prevKey + 1);
        if (!keepModalOpen) {
            setShowModal(false);
        }
        if (imageUploadRef.current && imageUploadRef.current.resetImageUploadState) {
            imageUploadRef.current.resetImageUploadState();
        }
    };

    const deleteProviderCategory = async (id) => {
        try {
            const packagesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/packages`);
            const allPackages = packagesResponse.data;
            const linkedPackages = allPackages.filter(pkg => pkg.prov_cat_id === id);

            if (linkedPackages.length > 0) {
                alert(`This provider category cannot be deleted as it has ${linkedPackages.length} package(s) assigned to it.`);
                return;
            }

            if (window.confirm("Are you sure you want to delete this provider category?")) {
                await axios.delete(`${process.env.REACT_APP_API_URL}/provider-categories/${id}`);
                fetchProviderCategories();
            }
        } catch (error) {
            console.error("Error deleting provider category:", error);
            alert("An error occurred while trying to delete the provider category.");
        }
    };

    // Helper functions
    const getProviderFullName = (providerId) => {
        const provider = providers.find(p => p.provider_id === providerId);
        return provider ? provider.provider_full_name : 'Unknown';
    };

    const getCategoryName = (categoryId) => {
        const category = categories.find(c => c.cat_id === categoryId);
        return category ? category.cat_name : 'Unknown';
    };

    const getTechnologyClassName = (techClassId) => {
        const techClass = technologyClasses.find(tc => tc.tech_class_id === techClassId);
        return techClass ? techClass.tech_class_name : 'Unknown';
    };

    const applySorting = (categoriesToSort) => {
        const { key, direction } = sortConfig;
        if (!key) return categoriesToSort;

        return [...categoriesToSort].sort((a, b) => {
            // Handle numeric values
            if (['prov_cat_id', 'prov_cat_priority'].includes(key)) {
                return direction === 'asc' ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key]);
            }
            // Handle provider names
            if (key === 'provider') {
                const providerA = providers.find(p => p.provider_id === a.provider_id)?.provider_full_name || '';
                const providerB = providers.find(p => p.provider_id === b.provider_id)?.provider_full_name || '';
                return direction === 'asc' ? providerA.localeCompare(providerB) : providerB.localeCompare(providerA);
            }
            // Handle category names
            if (key === 'category') {
                const categoryA = categories.find(c => c.cat_id === a.cat_id)?.cat_name || '';
                const categoryB = categories.find(c => c.cat_id === b.cat_id)?.cat_name || '';
                return direction === 'asc' ? categoryA.localeCompare(categoryB) : categoryB.localeCompare(categoryA);
            }
            // Handle technology class names
            if (key === 'tech_class') {
                const techClassA = technologyClasses.find(tc => tc.tech_class_id === a.tech_class_id)?.tech_class_name || '';
                const techClassB = technologyClasses.find(tc => tc.tech_class_id === b.tech_class_id)?.tech_class_name || '';
                return direction === 'asc' ? techClassA.localeCompare(techClassB) : techClassB.localeCompare(techClassA);
            }
            // Default string comparison
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const sortProviderCategories = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setProviderCategories(applySorting([...providerCategories]));
    };

    const truncateDescription = (text, id) => {
        if (!text) return '-';
        if (expandedDescriptions[id]) return text;
        
        return text.length > 50 ? (
            <span>
                {text.substring(0, 50)}...
                <a 
                    href="#" 
                    onClick={(e) => {
                        e.preventDefault();
                        setExpandedDescriptions(prev => ({
                            ...prev,
                            [id]: true
                        }));
                    }}
                    className="text-primary ms-1"
                >
                    more
                </a>
            </span>
        ) : text;
    };

    return (
        <div className="container mt-4">
            <h1>Provider Categories</h1>
            <button className="btn btn-primary mb-3" onClick={() => openModal()}>Add Provider Category</button>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => sortProviderCategories('prov_cat_id')} style={{cursor: 'pointer'}}>
                            ID {sortConfig.key === 'prov_cat_id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortProviderCategories('provider')} style={{cursor: 'pointer'}}>
                            Provider {sortConfig.key === 'provider' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortProviderCategories('category')} style={{cursor: 'pointer'}}>
                            Category {sortConfig.key === 'category' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortProviderCategories('tech_class')} style={{cursor: 'pointer'}}>
                            Technology Class {sortConfig.key === 'tech_class' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortProviderCategories('prov_cat_name')} style={{cursor: 'pointer'}}>
                            Name {sortConfig.key === 'prov_cat_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortProviderCategories('prov_cat_description')} style={{cursor: 'pointer'}}>
                            Description {sortConfig.key === 'prov_cat_description' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th>Image</th>
                        <th onClick={() => sortProviderCategories('prov_cat_priority')} style={{cursor: 'pointer'}}>
                            Priority {sortConfig.key === 'prov_cat_priority' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortProviderCategories('status')} style={{cursor: 'pointer'}}>
                            Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                      
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {providerCategories.map(pc => (
                        <tr key={pc.prov_cat_id}>
                            <td>{pc.prov_cat_id}</td>
                            <td>{getProviderFullName(pc.provider_id)}</td>
                            <td>{getCategoryName(pc.cat_id)}</td>
                            <td>{getTechnologyClassName(pc.tech_class_id)}</td>
                            <td>{pc.prov_cat_name}</td>
                            <td>
                                {truncateDescription(pc.prov_cat_description, pc.prov_cat_id)}
                                {expandedDescriptions[pc.prov_cat_id] && (
                                    <a 
                                        href="#" 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setExpandedDescriptions(prev => ({
                                                ...prev,
                                                [pc.prov_cat_id]: false
                                            }));
                                        }}
                                        className="text-primary ms-1"
                                    >
                                        less
                                    </a>
                                )}
                            </td>
                            <td>
                                {pc.prov_cat_image_url && (
                                    <img 
                                        src={process.env.REACT_APP_IMAGE_URL + '/' + pc.prov_cat_image_url} 
                                        alt={pc.prov_cat_name} 
                                        style={{ width: '100px', height: '100px', objectFit: 'contain', borderRadius: '5px' }} 
                                    />
                                ) || <span className='table-warning'>No Image</span>}
                            </td>
                            <td>{pc.prov_cat_priority}</td>
                            <td>{pc.status}</td>
                            
                            <td>
                                <button className="btn btn-warning btn-sm me-2" onClick={() => openModal(pc)}>Edit</button>
                                <button className="btn btn-danger btn-sm" onClick={() => deleteProviderCategory(pc.prov_cat_id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Adding/Editing Provider Category */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="providerCategoryModalLabel" aria-hidden={!showModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="providerCategoryModalLabel">{editingCategoryId ? 'Edit Provider Category' : 'Add Provider Category'}</h5>
                        </div>
                        <form onSubmit={addOrUpdateCategory}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Provider*</label>
                                    <select 
                                        className="form-control" 
                                        value={categoryData.provider_id} 
                                        onChange={(e) => setCategoryData({ ...categoryData, provider_id: e.target.value })}
                                        required
                                    >
                                        <option value="">Select Provider</option>
                                        {providers.map(provider => (
                                            <option key={provider.provider_id} value={provider.provider_id}>
                                                {provider.provider_full_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Category*</label>
                                    <select 
                                        className="form-control" 
                                        value={categoryData.cat_id} 
                                        onChange={(e) => setCategoryData({ ...categoryData, cat_id: e.target.value })}
                                        required
                                    >
                                        <option value="">Select Category</option>
                                        {categories.map(category => (
                                            <option key={category.cat_id} value={category.cat_id}>
                                                {category.cat_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Technology Class*</label>
                                    <select 
                                        className="form-control" 
                                        value={categoryData.tech_class_id} 
                                        onChange={(e) => setCategoryData({ ...categoryData, tech_class_id: e.target.value })}
                                        required
                                    >
                                        <option value="">Select Technology Class</option>
                                        {technologyClasses.map(techClass => (
                                            <option key={techClass.tech_class_id} value={techClass.tech_class_id}>
                                                {techClass.tech_class_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Provider Category Name*</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        value={categoryData.prov_cat_name} 
                                        onChange={(e) => setCategoryData({ ...categoryData, prov_cat_name: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Provider Category Description</label>
                                    <textarea 
                                        className="form-control" 
                                        value={categoryData.prov_cat_description} 
                                        onChange={(e) => setCategoryData({ ...categoryData, prov_cat_description: e.target.value })}
                                        rows="3"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Provider Category Image (400x258)*</label>
                                    <div className="form-group">
                                        {initialImageUrl && (
                                            <img src={process.env.REACT_APP_IMAGE_URL + '/' + initialImageUrl} alt="Current Image" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                                        )}
                                    </div>
                                </div>
                                <ImageUpload 
                                    ref={imageUploadRef}
                                    onUploadSuccess={(url) => setCategoryData({ ...categoryData, prov_cat_image_url: url })}
                                    identifier="provider-category"
                                    key={imageUploadKey}
                                    category_id={3}
                                    required
                                />
                                <div className="form-group">
                                    <label>Priority</label>
                                    <input 
                                        type="number" 
                                        className="form-control" 
                                        value={categoryData.prov_cat_priority} 
                                        onChange={(e) => setCategoryData({ ...categoryData, prov_cat_priority: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Status</label>
                                    <select 
                                        className="form-control" 
                                        value={categoryData.status} 
                                        onChange={(e) => setCategoryData({ ...categoryData, status: e.target.value })}
                                    >
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="deleted">Deleted</option>
                                    </select>
                                </div>
                           
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => resetForm()}>Close</button>
                                <button type="submit" className="btn btn-primary">
                                    {editingCategoryId ? 'Update Provider Category' : 'Add Provider Category'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProviderCategories;
