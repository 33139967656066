import React from 'react';
import Packages from '../components/Packages';
import 'bootstrap/dist/css/bootstrap.min.css';

const PackagesPage = () => {
    return (
        <div className="container">
            
            <Packages />
        </div>
    );
};

export default PackagesPage;