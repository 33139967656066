import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ImageUpload from './ImageUpload';


const TeamMembers = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [branches, setBranches] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editingMemberId, setEditingMemberId] = useState(null);
    const [memberData, setMemberData] = useState({
        branch_id: '',
        team_member_name: '',
        team_member_image: '',
        team_member_description: '',
        team_member_email: '',
        team_member_tell: '',
        team_member_cell: '',
        team_member_sale_url: '',
        team_member_rt_queue: '',
        team_member_rt_username: '',
        team_member_type: 'sales',
        status: 'enabled'
    });
    const imageUploadRef = useRef();
    const [imageUploadKey, setImageUploadKey] = useState(0);

    useEffect(() => {
        fetchTeamMembers();
        fetchBranches();
    }, []);

    const fetchTeamMembers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/team-members`);
            setTeamMembers(response.data);
        } catch (error) {
            console.error('Error fetching team members:', error);
        }
    };

    const fetchBranches = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
            setBranches(response.data);
        } catch (error) {
            console.error('Error fetching branches:', error);
        }
    };

    const resetForm = () => {
        setMemberData({
            branch_id: '',
            team_member_name: '',
            team_member_image: '',
            team_member_description: '',
            team_member_email: '',
            team_member_tell: '',
            team_member_cell: '',
            team_member_sale_url: '',
            team_member_rt_queue: '',
            team_member_rt_username: '',
            team_member_type: 'sales',
            status: 'disabled'
        });
        setEditingMemberId(null);
        setShowModal(false);
        setImageUploadKey(prev => prev + 1);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMemberData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const startEditing = (member) => {
        setEditingMemberId(member.team_member_id);
        setMemberData({
            branch_id: member.branch_id,
            team_member_name: member.team_member_name,
            team_member_image: member.team_member_image || '',
            team_member_description: member.team_member_description,
            team_member_email: member.team_member_email,
            team_member_tell: member.team_member_tell,
            team_member_cell: member.team_member_cell,
            team_member_sale_url: member.team_member_sale_url,
            team_member_rt_queue: member.team_member_rt_queue,
            team_member_rt_username: member.team_member_rt_username,
            team_member_type: member.team_member_type,
            status: member.status
        });
        setShowModal(true);
    };

    const addOrUpdateMember = async (e) => {
        e.preventDefault();
        console.log('Submitting member data:', memberData);
        try {
            if (editingMemberId) {
                const response = await axios.put(`${process.env.REACT_APP_API_URL}/team-members/${editingMemberId}`, memberData);
                console.log('Update response:', response.data);
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/team-members`, memberData);
                console.log('Create response:', response.data);
            }
            fetchTeamMembers();
            resetForm();
        } catch (error) {
            console.error('Error saving team member:', error.response ? error.response.data : error);
        }
    };

    const deleteMember = async (memberId) => {
        if (window.confirm('Are you sure you want to delete this team member?')) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/team-members/${memberId}`);
                fetchTeamMembers();
            } catch (error) {
                console.error('Error deleting team member:', error);
            }
        }
    };

    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h2>Team Members</h2>
                <button className="btn btn-primary" onClick={() => setShowModal(true)}>Add Team Member</button>
            </div>

            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Image</th>
                            <th>Branch</th>
                            <th>Email</th>
                            <th>Tell</th>
                            <th>Cell</th>
                            <th>Sale URL</th>
                            <th>RT Queue</th>
                            <th>RT Username</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teamMembers.map(member => (
                            <tr key={member.team_member_id}>
                                <td>{member.team_member_id}</td>
                                <td>{member.team_member_name}</td>
                                <td>
                                    {member.team_member_image ? (
                                        <img 
                                            src={process.env.REACT_APP_IMAGE_URL + '/' + member.team_member_image} 
                                            alt={member.team_member_name} 
                                            style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '5px' }} 
                                        />
                                    ) : (
                                        <span className="text-muted">No image</span>
                                    )}
                                </td>
                                <td>{member.Branch?.branch_name}</td>
                                <td>{member.team_member_email}</td>
                                <td>{member.team_member_tell}</td>
                                <td>{member.team_member_cell}</td>
                                <td>{member.team_member_sale_url}</td>
                                <td>{member.team_member_rt_queue}</td>
                                <td>{member.team_member_rt_username}</td>
                                <td>{member.team_member_type}</td>
                                <td>{member.status}</td>
                                <td>
                                    <button className="btn btn-warning btn-sm me-2" onClick={() => startEditing(member)}>Edit</button>
                                    <button className="btn btn-danger btn-sm" onClick={() => deleteMember(member.team_member_id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal for Adding/Editing Team Member */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{editingMemberId ? 'Edit Team Member' : 'Add Team Member'}</h5>
                            <button type="button" className="close" onClick={resetForm}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={addOrUpdateMember}>
                                <div className="form-group mb-3">
                                    <label>Branch*</label>
                                    <select className="form-control" name="branch_id" value={memberData.branch_id} onChange={handleInputChange} required>
                                        <option value="">Select Branch</option>
                                        {branches.map(branch => (
                                            <option key={branch.branch_id} value={branch.branch_id}>
                                                {branch.branch_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Name*</label>
                                    <input type="text" className="form-control" name="team_member_name" value={memberData.team_member_name} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Image (300x300)</label>
                                    <ImageUpload 
                                        ref={imageUploadRef}
                                        onUploadSuccess={(url) => setMemberData(prev => ({ ...prev, team_member_image: url }))}
                                        identifier="team_member"
                                        key={imageUploadKey}
                                        category_id={6}
                                        initialImageUrl={memberData.team_member_image}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Description*</label>
                                    <textarea className="form-control" name="team_member_description" value={memberData.team_member_description} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Email*</label>
                                    <input type="email" className="form-control" name="team_member_email" value={memberData.team_member_email} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Tell*</label>
                                    <input type="text" className="form-control" name="team_member_tell" value={memberData.team_member_tell} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Cell*</label>
                                    <input type="text" className="form-control" name="team_member_cell" value={memberData.team_member_cell} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Sale URL*</label>
                                    <input type="text" className="form-control" name="team_member_sale_url" value={memberData.team_member_sale_url} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>RT Queue</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="team_member_rt_queue" 
                                        value={memberData.team_member_rt_queue} 
                                        onChange={handleInputChange} 
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>RT Username*</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="team_member_rt_username" 
                                        value={memberData.team_member_rt_username} 
                                        onChange={handleInputChange} 
                                        required
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Member Type*</label>
                                    <select 
                                        className="form-control" 
                                        name="team_member_type" 
                                        value={memberData.team_member_type} 
                                        onChange={handleInputChange} 
                                        required
                                    >
                                        <option value="Sales">Sales</option>
                                        <option value="Retention">Retention</option>
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Status*</label>
                                    <select className="form-control" name="status" value={memberData.status} onChange={handleInputChange} required>
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="deleted">Deleted</option>
                                    </select>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={resetForm}>Cancel</button>
                                    <button type="submit" className="btn btn-primary">{editingMemberId ? 'Update' : 'Add'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && <div className="modal-backdrop fade show"></div>}
        </div>
    );
};

export default TeamMembers;