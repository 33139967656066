import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Bundles = () => {
    const [bundles, setBundles] = useState([]);
    const [packages, setPackages] = useState([]);
    const [providerCategories, setProviderCategories] = useState([]);
    const [valueAddedProducts, setValueAddedProducts] = useState([]);
    const [bundleData, setBundleData] = useState({
        package_id: '',
       
        package_price: '',
        installation_price: '',
        bundle_priority: '',
        status: 'active',
        bundle_description: '',
        bundle_name: '',
        valueAddedProducts: []
    });
    const [editingBundleId, setEditingBundleId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedProviderCategory, setSelectedProviderCategory] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null); // State for selected category
    const [filteredPackages, setFilteredPackages] = useState([]); // State for filtered packages
    const [sortedColumn, setSortedColumn] = useState('bundle_id');
    const [sortDirection, setSortDirection] = useState('asc');
    const [valueAddedProductsData, setValueAddedProductsData] = useState([{ vap_id: '', quantity: 1, price: 0 }]);
    const [selectedPackageDetails, setSelectedPackageDetails] = useState(null);
    const [sortConfig, setSortConfig] = useState({
        key: 'bundle_id',
        direction: 'asc'
    });
    const [sortConfigs, setSortConfigs] = useState(() => {
        const savedSortConfigs = Cookies.get('bundlesSortConfigs');
        return savedSortConfigs ? JSON.parse(savedSortConfigs) : [
            { key: 'bundle_id', direction: 'asc' },
            { key: '', direction: 'asc' },
            { key: '', direction: 'asc' }
        ];
    });
    const [groupedBundles, setGroupedBundles] = useState({});
    const [expandedDescriptions, setExpandedDescriptions] = useState({});
    const sortOptions = [
        { value: '', label: 'None' },
        { value: 'bundle_id', label: 'Bundle ID' },
        { value: 'package_name', label: 'Package Name' },
        { value: 'bundle_name', label: 'Bundle Name' },
        { value: 'package_price', label: 'Package Price' },
        { value: 'installation_price', label: 'Installation Price' },
        { value: 'bundle_description', label: 'Description' },
        { value: 'bundle_priority', label: 'Priority' },
        { value: 'status', label: 'Status' },
        { value: 'download_speed_mb', label: 'Download Speed' },
        { value: 'upload_speed_mb', label: 'Upload Speed' }
    ];

    const navigate = useNavigate();

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                await Promise.all([
                    fetchPackages(),
                    fetchProviderCategories(),
                    fetchValueAddedProducts()
                ]);
                // Only fetch bundles after other data is loaded
                await fetchBundles();
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchAllData();
    }, []);

    useEffect(() => {
        const storedBundleData = localStorage.getItem('createBundle');
        if (storedBundleData && packages.length > 0) {
            const data = JSON.parse(storedBundleData);
            
            // Set the provider category first
            setSelectedCategory(parseInt(data.prov_cat_id));
            
            // Find the package details
            const selectedPackage = packages.find(pkg => pkg.package_id === data.package_id);
            if (selectedPackage) {
                // Filter packages based on the selected category
                const filtered = packages.filter(pkg => pkg.prov_cat_id === parseInt(data.prov_cat_id));
                setFilteredPackages(filtered);
                
                // Set initial bundle data with package details
                setBundleData({
                    package_id: data.package_id.toString(),
                    package_price: selectedPackage.sell_price_incl,
                    installation_price: selectedPackage.installation,
                    bundle_name: selectedPackage.package_name || '',
                    bundle_priority: '',
                    status: 'active',
                    bundle_description: data.package_description || selectedPackage.package_description || '',  
                    valueAddedProducts: []
                });
                
                setSelectedPackageDetails(selectedPackage);
                setValueAddedProductsData([{ vap_id: '', quantity: 1, price: 0, vap_cost: null, vap_sell: null }]);
                
                if (data.showModal) {
                    setShowModal(true);
                }
            }
            
            localStorage.removeItem('createBundle');
        }
    }, [packages]);

    const applySorting = (bundlesToSort, configs = sortConfigs) => {
        return [...bundlesToSort].sort((a, b) => {
            for (const config of configs) {
                if (!config.key) continue;
                
                const { key, direction } = config;
                let comparison = 0;

                // Handle numeric values
                if (['bundle_id', 'package_price', 'installation_price', 'bundle_priority'].includes(key)) {
                    comparison = direction === 'asc' ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key]);
                }
                // Handle package-related fields
                else if (['download_speed_mb', 'upload_speed_mb'].includes(key)) {
                    const packageA = packages.find(pkg => pkg.package_id === a.package_id) || {};
                    const packageB = packages.find(pkg => pkg.package_id === b.package_id) || {};
                    comparison = direction === 'asc' ? 
                        parseFloat(packageA[key] || 0) - parseFloat(packageB[key] || 0) : 
                        parseFloat(packageB[key] || 0) - parseFloat(packageA[key] || 0);
                }
                // Handle package name
                else if (key === 'package_name') {
                    const packageA = packages.find(pkg => pkg.package_id === a.package_id)?.package_name || '';
                    const packageB = packages.find(pkg => pkg.package_id === b.package_id)?.package_name || '';
                    comparison = direction === 'asc' ? packageA.localeCompare(packageB) : packageB.localeCompare(packageA);
                }
                // Default string comparison
                else {
                    if (a[key] < b[key]) comparison = direction === 'asc' ? -1 : 1;
                    if (a[key] > b[key]) comparison = direction === 'asc' ? 1 : -1;
                }
                
                if (comparison !== 0) return comparison;
            }
            return 0;
        });
    };

    const fetchBundles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/bundles`);
            const sortedBundles = applySorting(response.data);
            setBundles(sortedBundles);
        } catch (error) {
            console.error("Error fetching bundles:", error);
        }
    };

    const fetchPackages = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/packages`);
        setPackages(response.data);
    };

    const fetchProviderCategories = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider-categories`);
        setProviderCategories(response.data);
    };

    const fetchValueAddedProducts = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/value-added-products`);
        setValueAddedProducts(response.data);
    };

    const addOrUpdateBundle = async () => {
        const bundlePayload = {
            package_id: bundleData.package_id,
            package_price: bundleData.package_price,
            installation_price: bundleData.installation_price,
            bundle_priority: bundleData.bundle_priority,
            status: bundleData.status,
            bundle_description: bundleData.bundle_description,
            bundle_name: bundleData.bundle_name,
            value_added_products: valueAddedProductsData.map(vap => ({
                vap_id: vap.vap_id,
                quantity: vap.quantity,
                price: vap.price
            }))
        };

        try {
            if (editingBundleId) {
                await axios.put(`${process.env.REACT_APP_API_URL}/bundles/${editingBundleId}`, bundlePayload);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/bundles`, bundlePayload);
            }
            resetForm();
            fetchBundles();
        } catch (error) {
            console.error("Error adding/updating bundle:", error);
        }
    };

    const resetForm = () => {
        setBundleData({
            package_id: '',
            package_price: '',
            installation_price: '',
            bundle_priority: '',
            status: 'active',
            bundle_description: '',
            bundle_name: '',
            valueAddedProducts: []
        });
        setEditingBundleId(null);
        setValueAddedProductsData([{ vap_id: '', quantity: 1, price:' ', vap_cost: null, vap_sell: null }]);
        setShowModal(false);
        setSelectedCategory(null);
    };

    const deleteBundle = async (id) => {
        if (window.confirm("Are you sure you want to delete this bundle?")) {
            await axios.delete(`${process.env.REACT_APP_API_URL}/bundles/${id}`);
            fetchBundles();
        }
    };

    const startEditing = (bundle) => {
        const associatedPackage = packages.find(pkg => pkg.package_id === bundle.package_id);
        const associatedVAPs = bundle.ValueAddedProducts.map(vapData => ({
            vap_id: vapData.vap_id,
            quantity: vapData.BundleValueAddedProduct.quantity,
            price: vapData.BundleValueAddedProduct.price
        }));

        setBundleData({
            package_id: bundle.package_id,
            package_price: bundle.package_price,
            installation_price: bundle.installation_price,
            bundle_priority: bundle.bundle_priority,
            status: bundle.status,
            bundle_description: bundle.bundle_description,
            bundle_name: bundle.bundle_name,
        });

        setValueAddedProductsData(associatedVAPs);
        setEditingBundleId(bundle.bundle_id);
        setSelectedCategory(associatedPackage.prov_cat_id);
        setShowModal(true);
    };



    const handleProviderCategoryChange = (e) => {
        const selectedCategoryValue = parseInt(e.target.value, 10); // Convert to integer
        setSelectedCategory(selectedCategoryValue); // Update the state
        setBundleData({ ...bundleData, package_id: '' }); // Reset package selection
        console.log("Selected Category:", selectedCategoryValue); // Log the selected category
    };

    useEffect(() => {
        // Filter packages whenever selectedCategory changes
        const filtered = packages.filter(pkg => {
            console.log("Filtering Package ID:", pkg.prov_cat_id); // Log each package's prov_cat_id
            console.log("Selected Category:", selectedCategory); // Log the selected category
            return pkg.prov_cat_id === selectedCategory; // Ensure this matches the selected category
        });
        setFilteredPackages(filtered); // Update filtered packages state
        console.log("Filtered Packages:", filtered); // Log the filtered packages
    }, [selectedCategory, packages]); // Run this effect when selectedCategory or packages change

    const handlePackageChange = (e, isCopying = false) => {
        const packageId = e.target.value;
        console.log('Package change - Package ID:', packageId, 'Is Copying:', isCopying);
        const selectedPackage = packages.find(pkg => pkg.package_id.toString() === packageId);
        console.log('Selected package:', selectedPackage);
        
        if (!isCopying) {
            setBundleData(prevData => ({
                ...prevData,
                package_id: packageId,
                package_price: selectedPackage ? selectedPackage.sell_price_incl : '',
                installation_price: selectedPackage ? selectedPackage.installation : '',
                bundle_name: selectedPackage ? selectedPackage.package_name || '' : '',
                bundle_description: selectedPackage ? selectedPackage.package_description || '' : ''
            }));
        } else {
            setBundleData(prevData => ({
                ...prevData,
                package_id: packageId
            }));
        }

        setSelectedPackageDetails(selectedPackage);
        console.log('Set selected package details:', selectedPackage);
    };

    const handleVAPChange = (index, value) => {
        console.log('handleVAPChange called with index:', index, 'and value:', value);
        const updatedVAPs = [...valueAddedProductsData];
        const selectedVAP = valueAddedProducts.find(vap => vap.vap_id.toString() === value);
        console.log('Selected VAP:', selectedVAP);

        if (selectedVAP) {
            updatedVAPs[index] = {
                ...updatedVAPs[index],
                vap_id: value,
                price: selectedVAP.vap_sell,
                vap_cost: selectedVAP.vap_cost,
                vap_sell: selectedVAP.vap_sell
            };
        } else {
            updatedVAPs[index] = {
                ...updatedVAPs[index],
                vap_id: value,
                price: 0,
                vap_cost: null,
                vap_sell: null
            };
        }

        console.log('Updated VAPs:', updatedVAPs);
        setValueAddedProductsData(updatedVAPs);
    };

    const handleQuantityChange = (index, value) => {
        const updatedVAPs = [...valueAddedProductsData];
        updatedVAPs[index].quantity = value;
        setValueAddedProductsData(updatedVAPs);
    };

    const sortBundles = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setBundles(applySorting([...bundles]));
    };

    const removeVAP = (index) => {
        const updatedVAPs = valueAddedProductsData.filter((_, i) => i !== index);
        setValueAddedProductsData(updatedVAPs);
    };

    const addNewVAP = () => {
        if (valueAddedProductsData.length < valueAddedProducts.length) {
            setValueAddedProductsData([...valueAddedProductsData, { vap_id: '', quantity: 1, price: 0, vap_cost: null, vap_sell: null }]);
        } else {
            alert("You've added all available VAPs.");
        }
    };

    useEffect(() => {
        const fetchValueAddedProducts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/value-added-products`);
                console.log('Fetched value-added products:', response.data);
                setValueAddedProducts(response.data);
            } catch (error) {
                console.error('Error fetching value-added products:', error);
            }
        };

        fetchValueAddedProducts();
    }, []);

    console.log('valueAddedProducts:', valueAddedProducts);

    const copyBundle = (bundle) => {
        const associatedPackage = packages.find(pkg => pkg.package_id === bundle.package_id);
        
        // First set the provider category and handle package change
        setSelectedProviderCategory(associatedPackage ? associatedPackage.prov_cat_id.toString() : '');
        handleProviderCategoryChange({ target: { value: associatedPackage.prov_cat_id.toString() } });
        handlePackageChange({ target: { value: bundle.package_id.toString() } }, true);

        // Then set the bundle data
        const bundleDataToSet = {
            package_id: bundle.package_id.toString(),
            bundle_description: bundle.bundle_description,
            bundle_name: bundle.bundle_name,
            package_price: bundle.package_price.toString(),
            installation_price: bundle.installation_price.toString(),
            bundle_priority: bundle.bundle_priority.toString(),
            status: bundle.status,
        };

        setBundleData(bundleDataToSet);
        
        const associatedVAPs = bundle.ValueAddedProducts ? bundle.ValueAddedProducts.map(vapData => ({
            vap_id: vapData.vap_id.toString(),
            quantity: vapData.BundleValueAddedProduct?.quantity || 1,
            price: vapData.BundleValueAddedProduct?.price || 0,
            vap_cost: vapData.vap_cost,
            vap_sell: vapData.vap_sell
        })) : [];

        setValueAddedProductsData(associatedVAPs.length > 0 ? associatedVAPs : [{ vap_id: '', quantity: 1, price: 0, vap_cost: null, vap_sell: null }]);
        setSelectedPackageDetails({
            ...associatedPackage,
            sell_price_incl: bundle.package_price,
            installation: bundle.installation_price
        });
        
        setShowModal(true);
    };

    const setDefaultBundle = async (bundleId, provCatId) => {
        try {
            if (window.confirm("Set this as the default bundle? This will remove default status from any other bundle in this category.")) {
                await axios.put(`${process.env.REACT_APP_API_URL}/bundles/${bundleId}/set-default`, {
                    prov_cat_id: provCatId
                });
                fetchBundles(); // Refresh the list
            }
        } catch (error) {
            console.error("Error setting default bundle:", error);
            alert("An error occurred while setting the default bundle.");
        }
    };

    useEffect(() => {
        if (bundles.length > 0 && packages.length > 0 && providerCategories.length > 0) {
            const grouped = packages.reduce((acc, pkg) => {
                const associatedBundles = bundles.filter(bundle => bundle.package_id === pkg.package_id);
                if (associatedBundles.length > 0) {
                    const provCatId = pkg.prov_cat_id;
                    const providerCategory = providerCategories.find(category => category.prov_cat_id === provCatId);
                    const provCatName = providerCategory ? providerCategory.prov_cat_name : `Unknown-${provCatId}`;
                    if (!acc[provCatId]) {
                        acc[provCatId] = {
                            category: provCatName,
                            bundles: []
                        };
                    }
                    acc[provCatId].bundles.push(...associatedBundles);
                }
                return acc;
            }, {});
            
            setGroupedBundles(grouped);
        }
    }, [bundles, packages, providerCategories]);

    const truncateDescription = (text, id) => {
        if (!text) return '';
        if (expandedDescriptions[id]) return text;
        
        return text.length > 50 ? (
            <span>
                {text.substring(0, 50)}...
                <a 
                    href="#" 
                    onClick={(e) => {
                        e.preventDefault();
                        setExpandedDescriptions(prev => ({
                            ...prev,
                            [id]: true
                        }));
                    }}
                    className="text-primary ms-1"
                >
                    more
                </a>
            </span>
        ) : text;
    };

    return (
        <div className="container mt-4">
            <h1>Bundles</h1>
            <div className="row mb-3">
                {[0, 1, 2].map((index) => (
                    <div key={index} className="col-md-4">
                        <div className="d-flex align-items-center">
                            <select 
                                className="form-control me-2"
                                value={sortConfigs[index].key}
                                onChange={(e) => {
                                    const newConfigs = [...sortConfigs];
                                    newConfigs[index] = { ...newConfigs[index], key: e.target.value };
                                    setSortConfigs(newConfigs);
                                    const newGroupedBundles = {};
                                    Object.keys(groupedBundles).forEach(category => {
                                        newGroupedBundles[category] = {
                                            ...groupedBundles[category],
                                            bundles: applySorting([...groupedBundles[category].bundles], newConfigs)
                                        };
                                    });
                                    setGroupedBundles(newGroupedBundles);
                                    Cookies.set('bundlesSortConfigs', JSON.stringify(newConfigs), { expires: 30 });
                                }}
                            >
                                {sortOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {`Sort ${index + 1}: ${option.label}`}
                                    </option>
                                ))}
                            </select>
                            <button 
                                className="btn btn-outline-secondary"
                                onClick={() => {
                                    const newConfigs = [...sortConfigs];
                                    newConfigs[index].direction = newConfigs[index].direction === 'asc' ? 'desc' : 'asc';
                                    setSortConfigs(newConfigs);
                                    const newGroupedBundles = {};
                                    Object.keys(groupedBundles).forEach(category => {
                                        newGroupedBundles[category] = {
                                            ...groupedBundles[category],
                                            bundles: applySorting([...groupedBundles[category].bundles], newConfigs)
                                        };
                                    });
                                    setGroupedBundles(newGroupedBundles);
                                    Cookies.set('bundlesSortConfigs', JSON.stringify(newConfigs), { expires: 30 });
                                }}
                            >
                                {sortConfigs[index].direction === 'asc' ? '↑' : '↓'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <button className="btn btn-primary mb-3" onClick={() => { resetForm(); setShowModal(true); }}>Add Bundle</button>
            {Object.values(groupedBundles).map(group => (
                <div key={group.category} className="mb-4">
                    <h2>Provider Category: {group.category}</h2>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Package</th>
                                <th>Bundle Name</th>
                                <th>VAP</th>
                                <th>Package Price</th>
                                <th>Installation Price</th>
                                <th>Description</th>
                                <th>Priority</th>
                                <th>Status</th>
                                <th>DL</th>
                                <th>UL</th>
                                <th>Default</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {group.bundles.map(bundle => {
                                const associatedPackage = packages.find(pkg => pkg.package_id === bundle.package_id);
                                const associatedVAPs = bundle.ValueAddedProducts.map(vapData => {
                                    const vap = valueAddedProducts.find(v => v.vap_id === vapData.vap_id);
                                    return {
                                        ...vap,
                                        quantity: vapData.BundleValueAddedProduct.quantity,
                                        price: vapData.BundleValueAddedProduct.price
                                    };
                                }).filter(vap => vap); // Filter out any undefined values

                                return (
                                    <tr key={bundle.bundle_id}>
                                        <td>{bundle.bundle_id}</td>
                                        <td>{associatedPackage ? associatedPackage.package_name : 'Unknown'}</td>
                                        <td>{bundle.bundle_name || 'N/A'}</td>
                                        <td>
                                            {associatedVAPs && associatedVAPs.length > 0 ? (
                                                associatedVAPs.map(vap => (
                                                    <div key={vap.vap_id}>
                                                        {`${vap.vap_name} - QTY: ${vap.quantity}, PRICE: R${Number(vap.price).toFixed(2)}`}
                                                    </div>
                                                ))
                                            ) : (
                                                'No VAPs'
                                            )}
                                        </td>
                                        
                                        <td>R{bundle.package_price}</td>
                                        <td>R{bundle.installation_price}</td>
                                        <td>
                                            {truncateDescription(bundle.bundle_description, bundle.bundle_id)}
                                            {expandedDescriptions[bundle.bundle_id] && (
                                                <a 
                                                    href="#" 
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setExpandedDescriptions(prev => ({
                                                            ...prev,
                                                            [bundle.bundle_id]: false
                                                        }));
                                                    }}
                                                    className="text-primary ms-1"
                                                >
                                                    less
                                                </a>
                                            )}
                                        </td>
                                        <td>{bundle.bundle_priority}</td>
                                        <td>{bundle.status}</td>
                                        <td>{associatedPackage ? associatedPackage.download_speed_mb : 'N/A'}</td>
                                        <td>{associatedPackage ? associatedPackage.upload_speed_mb : 'N/A'}</td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <button 
                                                    className={`btn btn-sm ${bundle.bundle_default ? 'btn-success' : 'btn-outline-success'}`}
                                                    onClick={() => setDefaultBundle(bundle.bundle_id, bundle.Package?.prov_cat_id)}
                                                    title={bundle.bundle_default ? 'Default Bundle' : 'Set as Default'}
                                                >
                                                    <i className="fas fa-star"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <button className="btn btn-warning btn-sm me-2" onClick={() => startEditing(bundle)}>Edit</button>
                                                <button className="btn btn-danger btn-sm me-2" onClick={() => deleteBundle(bundle.bundle_id)}>Delete</button>
                                                <button className="btn btn-info btn-sm me-2" onClick={() => copyBundle(bundle)}>Copy</button>
                                                <button 
                                                    className="btn btn-primary btn-sm me-2" 
                                                    onClick={() => {
                                                        localStorage.setItem('createPromotionalBundle', JSON.stringify({
                                                            package_id: bundle.package_id,
                                                            prov_cat_id: packages.find(pkg => pkg.package_id === bundle.package_id)?.prov_cat_id,
                                                            bundle_data: {
                                                                bundle_name: bundle.bundle_name,
                                                                bundle_description: bundle.bundle_description,
                                                                package_price: bundle.package_price,
                                                                installation_price: bundle.installation_price,
                                                                bundle_priority: bundle.bundle_priority,
                                                                value_added_products: bundle.ValueAddedProducts?.map(vap => ({
                                                                    vap_id: vap.vap_id,
                                                                    quantity: vap.BundleValueAddedProduct.quantity,
                                                                    price: vap.BundleValueAddedProduct.price,
                                                                    vap_cost: vap.vap_cost,
                                                                    vap_sell: vap.vap_sell
                                                                }))
                                                            },
                                                            showModal: true
                                                        }));
                                                        navigate('/promotional-bundles');
                                                    }}
                                                >
                                                    Promo
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ))}

            {/* Modal for Adding/Editing Bundle */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{editingBundleId ? 'Edit Bundle' : (bundleData.package_id ? 'Copy Bundle' : 'Add Bundle')}</h5>
                            
                        </div>
                        <form onSubmit={(e) => { e.preventDefault(); addOrUpdateBundle(); }}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>Provider Category*</label>
                                    <select className="form-control" value={selectedCategory} onChange={handleProviderCategoryChange} required>
                                        <option value="">Select Provider Category</option>
                                        {providerCategories.map(category => (
                                            <option key={category.prov_cat_id} value={category.prov_cat_id}>
                                                {category.prov_cat_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Package*</label>
                                    <select className="form-control" value={bundleData.package_id} onChange={handlePackageChange} required>
                                        <option value="">Select Package</option>
                                        {filteredPackages.map(pkg => (
                                            <option key={pkg.package_id} value={pkg.package_id}>
                                                {pkg.package_name} - Term: {pkg.term}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Bundle Name*</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        value={bundleData.bundle_name} 
                                        onChange={(e) => setBundleData({ ...bundleData, bundle_name: e.target.value })} 
                                        required 
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Bundle Description*</label>
                                    <textarea className="form-control" value={bundleData.bundle_description} onChange={(e) => setBundleData({ ...bundleData, bundle_description: e.target.value })} required />
                                </div>
                               
                              
                                <div className="form-group">
                                    <label>Package Price* (Selected Package Cost Price: R{selectedPackageDetails ? selectedPackageDetails.cost_price_incl : 'N/A'}, Sell Price: R{selectedPackageDetails ? selectedPackageDetails.sell_price_incl : 'N/A'})</label>
                                    <input type="number" className="form-control" value={bundleData.package_price} onChange={(e) => setBundleData({ ...bundleData, package_price: e.target.value })} required />
                                </div>
                                <div className="form-group">
                                    <label>Installation Price* (Selected Package Installation: R{selectedPackageDetails ? selectedPackageDetails.installation : 'N/A'})</label>
                                    <input type="number" className="form-control" value={bundleData.installation_price} onChange={(e) => setBundleData({ ...bundleData, installation_price: e.target.value })} required />
                                </div>
                                <div className="form-group">
                                    <label>Value Added Products</label>
                                    <div className="vap-bundle">
                                        {valueAddedProductsData.map((vap, index) => (
                                            <div key={index} className="mb-3">
                                                <select
                                                    className="form-control mb-2"
                                                    value={vap.vap_id}
                                                    onChange={(e) => handleVAPChange(index, e.target.value)}
                                                    required
                                                >
                                                    <option value="">Select VAP</option>
                                                    {valueAddedProducts
                                                        .filter(product => !valueAddedProductsData.some((v, i) => i !== index && v.vap_id === product.vap_id))
                                                        .map(product => (
                                                            <option key={product.vap_id} value={product.vap_id}>
                                                                {product.vap_name}
                                                            </option>
                                                        ))}
                                                </select>
                                                <label>Quantity*</label>
                                                <input
                                                    type="number"
                                                    className="form-control mb-2"
                                                    value={vap.quantity}
                                                    onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                                                    min="1"
                                                    required
                                                />
                                                <label>
                                                    VAP Price* 
                                                    {vap.vap_id && (
                                                        <span>
                                                            (Cost: R{vap.vap_cost || 'N/A'}, 
                                                            Sell: R{vap.vap_sell || 'N/A'})
                                                        </span>
                                                    )}
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control mb-2"
                                                    value={vap.price}
                                                    onChange={(e) => {
                                                        const updatedVAPs = [...valueAddedProductsData];
                                                        updatedVAPs[index].price = parseFloat(e.target.value);
                                                        setValueAddedProductsData(updatedVAPs);
                                                    }}
                                                    min="0"
                                                    required
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => removeVAP(index)}
                                                >
                                                    Remove VAP
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-primary bundle-vap-btn"
                                        onClick={addNewVAP}
                                    >
                                        Add Another VAP
                                    </button>
                                </div>
                                <div className="form-group">
                                    <label>Bundle Priority*</label>
                                    <input type="number" className="form-control" value={bundleData.bundle_priority} onChange={(e) => setBundleData({ ...bundleData, bundle_priority: e.target.value })} required />
                                </div>
                           
                                
                                <div className="form-group">
                                    <label>Status*</label>
                                    <select className="form-control" value={bundleData.status} onChange={(e) => setBundleData({ ...bundleData, status: e.target.value })} required>
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="deleted">Deleted</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={resetForm}>Close</button>
                                <button type="submit" className="btn btn-primary">{editingBundleId ? 'Update Bundle' : 'Add Bundle'}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Bundles;

