import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProvidersPage from './pages/ProvidersPage';
import PackagesPage from './pages/PackagesPage'; 
import CategoriesPage from './pages/CategoriesPage'; 
import BundlesPage from './pages/BundlesPage'; 
import ValueAddedProductsPage from './pages/ValueAddedProductsPage';
import ProviderCategoriesPage from './pages/ProviderCategoriesPage';
import TechnologyClassesPage from './pages/TechnologyClassesPage';
import Navbar from './components/Navbar';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './components/Dashboard';
import './App.css'; // Import your CSS file
import UserRegistration from './components/UserRegistration';
import EditUser from './components/EditUser';
import ManageUsers from './components/ManageUsers';
import PromotionCategoriesPage from './pages/PromotionCategoriesPage';
import PromotionalPackagesPage from './pages/PromotionalPackagesPage';
import PromotionalBundlesPage from './pages/PromotionalBundlesPage';
import Provider28EastPage from './pages/Provider28EastPage';
import BranchesPage from './pages/BranchesPage';
import TeamMembersPage from './pages/TeamMembersPage';
import NewsPage from './pages/NewsPage';
const App = () => {
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user')) || null);

    const toggleTheme = () => {
        setIsDarkTheme(prevTheme => !prevTheme);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setCurrentUser(null);
        localStorage.removeItem('user');
        sessionStorage.removeItem('token');
    };

    return (
        <Router>
            <div className={isDarkTheme ? 'dark-theme' : 'light-theme'}>
                <Navbar 
                    toggleTheme={toggleTheme} 
                    isLoggedIn={isLoggedIn} 
                    currentUser={currentUser} 
                    handleLogout={handleLogout}
                />
                <div>
                    <Routes>
                        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} setCurrentUser={setCurrentUser} />} />
                        <Route path="/" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <Dashboard />
                            </ProtectedRoute>
                        } />
                      
                        <Route path="/providers" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <ProvidersPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/provider-28east" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <Provider28EastPage />
                            </ProtectedRoute>
                        } />

                        <Route path="/packages" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <PackagesPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/categories" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <CategoriesPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/bundles" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <BundlesPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/valueaddedproducts" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <ValueAddedProductsPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/provider-categories" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <ProviderCategoriesPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/technology-classes" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <TechnologyClassesPage />
                            </ProtectedRoute>
                        } />
                        <Route 
                          path="/register-user" 
                          element={
                            <ProtectedRoute isLoggedIn={isLoggedIn} requiredRole="admin">
                              <UserRegistration />
                            </ProtectedRoute>
                          } 
                        />
                        <Route 
                          path="/edit-user" 
                          element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                              <EditUser />
                            </ProtectedRoute>
                          } 
                        />
                        <Route 
                          path="/manage-users" 
                          element={
                            <ProtectedRoute isLoggedIn={isLoggedIn} requiredRole="admin">
                              <ManageUsers />
                            </ProtectedRoute>
                          } 
                        />
                        <Route path="/promotion-categories" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <PromotionCategoriesPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/promotional-packages" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <PromotionalPackagesPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/promotional-bundles" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <PromotionalBundlesPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/branches" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <BranchesPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/team-members" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <TeamMembersPage />
                            </ProtectedRoute>
                        } />
                        <Route path="/news" element={
                            <ProtectedRoute isLoggedIn={isLoggedIn}>
                                <NewsPage />
                            </ProtectedRoute>
                        } />
                    </Routes>
                </div>
            </div>
        </Router>
    );
};

export default App;
