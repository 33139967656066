import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Branches = () => {
    const [branches, setBranches] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editingBranchId, setEditingBranchId] = useState(null);
    const [branchData, setBranchData] = useState({
        branch_name: '',
        branch_address: '',
        branch_google_map_link: '',
        branch_contact_number: '',
        branch_email: '',
        branch_hours: '',
        branch_priority: 0,
        status: 'disabled'
    });

    useEffect(() => {
        fetchBranches();
    }, []);

    const fetchBranches = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
            setBranches(response.data);
        } catch (error) {
            console.error('Error fetching branches:', error);
        }
    };

    const resetForm = () => {
        setBranchData({
            branch_name: '',
            branch_address: '',
            branch_google_map_link: '',
            branch_contact_number: '',
            branch_email: '',
            branch_hours: '',
            branch_priority: 0,
            status: 'disabled'
        });
        setEditingBranchId(null);
        setShowModal(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBranchData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const startEditing = (branch) => {
        setEditingBranchId(branch.branch_id);
        setBranchData({
            branch_name: branch.branch_name,
            branch_address: branch.branch_address,
            branch_google_map_link: branch.branch_google_map_link,
            branch_contact_number: branch.branch_contact_number,
            branch_email: branch.branch_email,
            branch_hours: branch.branch_hours,
            branch_priority: branch.branch_priority,
            status: branch.status
        });
        setShowModal(true);
    };

    const addOrUpdateBranch = async (e) => {
        e.preventDefault();
        try {
            if (editingBranchId) {
                await axios.put(`${process.env.REACT_APP_API_URL}/branches/${editingBranchId}`, branchData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/branches`, branchData);
            }
            fetchBranches();
            resetForm();
        } catch (error) {
            console.error('Error saving branch:', error);
        }
    };

    const deleteBranch = async (branchId) => {
        if (window.confirm('Are you sure you want to delete this branch?')) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/branches/${branchId}`);
                fetchBranches();
            } catch (error) {
                console.error('Error deleting branch:', error);
            }
        }
    };

    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h2>Branches</h2>
                <button className="btn btn-primary" onClick={() => setShowModal(true)}>Add Branch</button>
            </div>

            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Contact Number</th>
                            <th>Email</th>
                            <th>Hours</th>
                            <th>Priority</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {branches.map(branch => (
                            <tr key={branch.branch_id}>
                                <td>{branch.branch_id}</td>
                                <td>{branch.branch_name}</td>
                                <td>{branch.branch_address}</td>
                                <td>{branch.branch_contact_number}</td>
                                <td>{branch.branch_email}</td>
                                <td>{branch.branch_hours}</td>
                                <td>{branch.branch_priority}</td>
                                <td>{branch.status}</td>
                                <td>
                                    <button className="btn btn-warning btn-sm me-2" onClick={() => startEditing(branch)}>Edit</button>
                                    <button className="btn btn-danger btn-sm" onClick={() => deleteBranch(branch.branch_id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal for Adding/Editing Branch */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{editingBranchId ? 'Edit Branch' : 'Add Branch'}</h5>
                            <button type="button" className="close" onClick={resetForm}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={addOrUpdateBranch}>
                                <div className="form-group mb-3">
                                    <label>Branch Name*</label>
                                    <input type="text" className="form-control" name="branch_name" value={branchData.branch_name} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Address*</label>
                                    <input type="text" className="form-control" name="branch_address" value={branchData.branch_address} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Google Map Link*</label>
                                    <input type="text" className="form-control" name="branch_google_map_link" value={branchData.branch_google_map_link} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Contact Number*</label>
                                    <input type="text" className="form-control" name="branch_contact_number" value={branchData.branch_contact_number} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Email*</label>
                                    <input type="email" className="form-control" name="branch_email" value={branchData.branch_email} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Hours*</label>
                                    <input type="text" className="form-control" name="branch_hours" value={branchData.branch_hours} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Priority*</label>
                                    <input type="number" className="form-control" name="branch_priority" value={branchData.branch_priority} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Status*</label>
                                    <select className="form-control" name="status" value={branchData.status} onChange={handleInputChange} required>
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="deleted">Deleted</option>
                                    </select>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={resetForm}>Cancel</button>
                                    <button type="submit" className="btn btn-primary">{editingBranchId ? 'Update' : 'Add'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && <div className="modal-backdrop fade show"></div>}
        </div>
    );
};

export default Branches;