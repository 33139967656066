import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import ImageUpload from './ImageUpload'; // Import the ImageUpload component

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [categoryData, setCategoryData] = useState({
        cat_name: '',
        cat_28east: '',
        status: 'Active',
        category_image: ''
    });
    const [editingCategoryId, setEditingCategoryId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [initialImageUrl, setInitialImageUrl] = useState(null);
    const [imageUploadKey, setImageUploadKey] = useState(0);
    const imageUploadRef = useRef();
    const [sortConfig, setSortConfig] = useState({
        key: 'cat_id',
        direction: 'asc'
    });

    useEffect(() => {
        fetchCategories();
    }, []);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`);
            const sortedCategories = applySorting(response.data);
            setCategories(sortedCategories);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const fetchProviderCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider-categories`);
            return response.data;
        } catch (error) {
            console.error("Error fetching provider categories:", error);
            return [];
        }
    };

    const openModal = (category = null) => {
        if (category) {
            setCategoryData({
                cat_name: category.cat_name,
                cat_28east: category.cat_28east,
                status: category.status,
                category_image: category.category_image
            });
            setEditingCategoryId(category.cat_id);
            setInitialImageUrl(category.category_image);
        } else {
            resetForm(true);
        }
        setShowModal(true);
        if (imageUploadRef.current && imageUploadRef.current.resetImageUploadState) {
            imageUploadRef.current.resetImageUploadState();
        }
    };

    const addOrUpdateCategory = async () => {
        try {
            if (editingCategoryId) {
                await axios.put(`${process.env.REACT_APP_API_URL}/categories/${editingCategoryId}`, categoryData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/categories`, categoryData);
            }
            resetForm();
            fetchCategories();
        } catch (error) {
            console.error("Error saving category:", error);
        }
    };

    const resetForm = (keepModalOpen = false) => {
        setCategoryData({
            cat_name: '',
            cat_28east: '',
            status: 'Active',
            category_image: ''
        });
        setEditingCategoryId(null);
        setInitialImageUrl(null);
        setImageUploadKey(prevKey => prevKey + 1);
        if (!keepModalOpen) {
            setShowModal(false);
        }
        if (imageUploadRef.current && imageUploadRef.current.resetImageUploadState) {
            imageUploadRef.current.resetImageUploadState();
        }
    };

    const deleteCategory = async (id) => {
        try {
            const providerCategories = await fetchProviderCategories();
            const linkedProviderCategories = providerCategories.filter(category => category.cat_id === id);

            if (linkedProviderCategories.length > 0) {
                alert(`This category cannot be deleted as it is linked to ${linkedProviderCategories.length} provider categorie(s).`);
                return;
            }

            if (window.confirm("Are you sure you want to delete this category?")) {
                await axios.delete(`${process.env.REACT_APP_API_URL}/categories/${id}`);
                fetchCategories();
            }
        } catch (error) {
            console.error("Error deleting category:", error);
            alert("An error occurred while trying to delete the category.");
        }
    };

    const applySorting = (categoriesToSort) => {
        const { key, direction } = sortConfig;
        if (!key) return categoriesToSort;

        return [...categoriesToSort].sort((a, b) => {
            // Handle numeric values
            if (['cat_id'].includes(key)) {
                return direction === 'asc' ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key]);
            }
            // Default string comparison
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const sortCategories = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setCategories(applySorting([...categories]));
    };

    return (
        <div className="container mt-4">
            <h1>Categories</h1>
            <button className="btn btn-primary mb-3" onClick={() => setShowModal(true)}>Add Category</button>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => sortCategories('cat_id')} style={{cursor: 'pointer'}}>
                            ID {sortConfig.key === 'cat_id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortCategories('cat_name')} style={{cursor: 'pointer'}}>
                            Category Name {sortConfig.key === 'cat_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortCategories('cat_28east')} style={{cursor: 'pointer'}}>
                            28 East Name {sortConfig.key === 'cat_28east' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        
                        <th onClick={() => sortCategories('status')} style={{cursor: 'pointer'}}>
                            Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {applySorting(categories).map(category => (
                        <tr key={category.cat_id}>
                            <td>{category.cat_id}</td>
                            <td>{category.cat_name}</td>
                            <td>{category.cat_28east}</td>
                           
                            <td>{category.status}</td>
                            <td>
                                <button className="btn btn-warning btn-sm me-2" onClick={() => openModal(category)}>Edit</button>
                                <button className="btn btn-danger btn-sm" onClick={() => deleteCategory(category.cat_id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Adding/Editing Category */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="categoryModalLabel" aria-hidden={!showModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="categoryModalLabel">{editingCategoryId ? 'Edit Category' : 'Add Category'}</h5>
                        </div>
                        <form onSubmit={(e) => { e.preventDefault(); addOrUpdateCategory(); }} className="p-3">
                            <div className="form-group mb-3">
                                <label>Category Name*</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    value={categoryData.cat_name} 
                                    onChange={(e) => setCategoryData({ ...categoryData, cat_name: e.target.value })} 
                                    required
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>28 East Name*</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    value={categoryData.cat_28east} 
                                    onChange={(e) => setCategoryData({ ...categoryData, cat_28east: e.target.value })} 
                                    required
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Status*</label>
                                <select 
                                    className="form-control" 
                                    value={categoryData.status} 
                                    onChange={(e) => setCategoryData({ ...categoryData, status: e.target.value })}
                                    required
                                >
                                    <option value="Active">Active</option>
                                    <option value="Disabled">Disabled</option>
                                    <option value="Deleted">Deleted</option>
                                </select>
                            </div>
                    
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => resetForm()}>Close</button>
                                <button type="submit" className="btn btn-primary">
                                    {editingCategoryId ? 'Update Category' : 'Add Category'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Categories;