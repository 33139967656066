import React from 'react';
import News from '../components/News';
import 'bootstrap/dist/css/bootstrap.min.css';

const NewsPage = () => {
    return (
        <div className="container">
            
            <News />
        </div>
    );
};

export default NewsPage;