import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ImageUpload from './ImageUpload';

const ValueAddedProducts = () => {
    const [valueAddedProducts, setValueAddedProducts] = useState([]);
    const [productData, setProductData] = useState({
        vap_sku: '',
        vap_name: '',
        vap_desc: '',
        vap_image_url: '',
        vap_cost: '',
        vap_sell: '',
        status: 'active'
    });
    const [editingProductId, setEditingProductId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [initialImageUrl, setInitialImageUrl] = useState(null);
    const [imageUploadKey, setImageUploadKey] = useState(0);
    const imageUploadRef = useRef();
    const [sortConfig, setSortConfig] = useState({
        key: 'vap_id',
        direction: 'asc'
    });
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    useEffect(() => {
        fetchValueAddedProducts();
    }, []);

    const fetchValueAddedProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/value-added-products`);
            const sortedProducts = applySorting(response.data);
            setValueAddedProducts(sortedProducts);
        } catch (error) {
            console.error("Error fetching value added products:", error);
        }
    };

    const openModal = (product = null) => {
        if (product) {
            setProductData({
                vap_sku: product.vap_sku,
                vap_name: product.vap_name,
                vap_desc: product.vap_desc,
                vap_image_url: product.vap_image_url,
                vap_cost: product.vap_cost,
                vap_sell: product.vap_sell,
               
                status: product.status
            });
            setEditingProductId(product.vap_id);
            setInitialImageUrl(product.vap_image_url);
        } else {
            resetForm(true);
        }
        setShowModal(true);
        if (imageUploadRef.current && imageUploadRef.current.resetImageUploadState) {
            imageUploadRef.current.resetImageUploadState();
        }
    };

    const addOrUpdateProduct = async (e) => {
        e.preventDefault();
        if (!productData.vap_image_url) {
            alert('Please upload an image');
            return;
        }
        try {
            const dataToSend = {
                ...productData,
                vap_priority: productData.vap_priority !== '' ? Number(productData.vap_priority) : 0
            };

            console.log('Data being sent:', dataToSend);

            if (editingProductId) {
                const response = await axios.put(`${process.env.REACT_APP_API_URL}/value-added-products/${editingProductId}`, dataToSend);
                console.log('Update response:', response.data);
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/value-added-products`, dataToSend);
                console.log('Create response:', response.data);
            }
            resetForm();
            fetchValueAddedProducts();
        } catch (error) {
            console.error("Error saving value added product:", error.response ? error.response.data : error.message);
        }
    };

    const resetForm = (keepModalOpen = false) => {
        setProductData({
            vap_sku: '',
            vap_name: '',
            vap_desc: '',
            vap_image_url: '',
            vap_cost: '',
            vap_sell: '',
            status: 'active'
        });
        setEditingProductId(null);
        setInitialImageUrl(null);
        setImageUploadKey(prevKey => prevKey + 1);
        if (!keepModalOpen) {
            setShowModal(false);
        }
        if (imageUploadRef.current && imageUploadRef.current.resetImageUploadState) {
            imageUploadRef.current.resetImageUploadState();
        }
    };

    const deleteValueAddedProduct = async (id) => {
        try {
            // Check for linked bundles
            const bundlesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/bundles`);
            const allBundles = bundlesResponse.data;
            const linkedBundles = allBundles.filter(bundle => 
                bundle.ValueAddedProducts.some(vap => vap.vap_id === id)
            );
    
            // Check for linked promotional bundles
            const promoBundlesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/promotional-bundles`);
            const allPromoBundles = promoBundlesResponse.data;
            const linkedPromoBundles = allPromoBundles.filter(bundle => 
                bundle.ValueAddedProducts.some(vap => vap.vap_id === id)
            );
    
            if (linkedBundles.length > 0 || linkedPromoBundles.length > 0) {
                alert(`This value added product cannot be deleted as it is linked to ${linkedBundles.length} bundle(s) and ${linkedPromoBundles.length} promotional bundle(s).`);
                return;
            }
    
            if (window.confirm("Are you sure you want to delete this value added product?")) {
                await axios.delete(`${process.env.REACT_APP_API_URL}/value-added-products/${id}`);
                fetchValueAddedProducts();
            }
        } catch (error) {
            console.error("Error deleting value added product:", error);
            alert("An error occurred while trying to delete the value added product.");
        }
    };

    const sortValueAddedProducts = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedProducts = [...valueAddedProducts].sort((a, b) => {
            // Handle numeric values
            if (['vap_id', 'vap_cost', 'vap_sell'].includes(key)) {
                return direction === 'asc' 
                    ? parseFloat(a[key]) - parseFloat(b[key]) 
                    : parseFloat(b[key]) - parseFloat(a[key]);
            }
            // Handle string values
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setValueAddedProducts(sortedProducts);
    };

    const applySorting = (productsToSort) => {
        const { key, direction } = sortConfig;
        if (!key) return productsToSort;

        return [...productsToSort].sort((a, b) => {
            // Handle numeric values
            if (['vap_id', 'vap_cost', 'vap_sell', 'vap_priority'].includes(key)) {
                return direction === 'asc' ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key]);
            }
            // Default string comparison
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const copyValueAddedProduct = (product) => {
        setProductData({
            vap_sku: product.vap_sku,
            vap_name: product.vap_name,
            vap_desc: product.vap_desc,
            vap_image_url: '',  // Reset image URL for new upload
            vap_cost: product.vap_cost,
            vap_sell: product.vap_sell,
            status: 'active'  // Default to active for new copy
        });
        
        setEditingProductId(null);  // Ensure we're creating a new product
        setInitialImageUrl(null);   // Reset initial image
        setShowModal(true);
        
        // Reset image upload component
        setImageUploadKey(prevKey => prevKey + 1);
        if (imageUploadRef.current && imageUploadRef.current.resetImageUploadState) {
            imageUploadRef.current.resetImageUploadState();
        }
    };

    const truncateDescription = (text, id) => {
        if (!text) return '-';
        if (expandedDescriptions[id]) return text;
        
        return text.length > 50 ? (
            <span>
                {text.substring(0, 50)}...
                <a 
                    href="#" 
                    onClick={(e) => {
                        e.preventDefault();
                        setExpandedDescriptions(prev => ({
                            ...prev,
                            [id]: true
                        }));
                    }}
                    className="text-primary ms-1"
                >
                    more
                </a>
            </span>
        ) : text;
    };

    return (
        <div className="container mt-4">
            <h1>Value Added Products</h1>
            <button className="btn btn-primary mb-3" onClick={() => openModal()}>Add Value Added Product</button>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => sortValueAddedProducts('vap_id')} style={{cursor: 'pointer'}}>
                            ID {sortConfig.key === 'vap_id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortValueAddedProducts('vap_sku')} style={{cursor: 'pointer'}}>
                            SKU {sortConfig.key === 'vap_sku' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortValueAddedProducts('vap_name')} style={{cursor: 'pointer'}}>
                            Name {sortConfig.key === 'vap_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortValueAddedProducts('vap_desc')} style={{cursor: 'pointer'}}>
                            Description {sortConfig.key === 'vap_desc' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th>Image</th>
                        <th onClick={() => sortValueAddedProducts('vap_cost')} style={{cursor: 'pointer'}}>
                            Cost {sortConfig.key === 'vap_cost' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortValueAddedProducts('vap_sell')} style={{cursor: 'pointer'}}>
                            Sell Price {sortConfig.key === 'vap_sell' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th onClick={() => sortValueAddedProducts('status')} style={{cursor: 'pointer'}}>
                            Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {valueAddedProducts.map(product => (
                        <tr key={product.vap_id}>
                            <td>{product.vap_id}</td>
                            <td>{product.vap_sku}</td>
                            <td>{product.vap_name}</td>
                            <td>
                                {truncateDescription(product.vap_desc, product.vap_id)}
                                {expandedDescriptions[product.vap_id] && (
                                    <a 
                                        href="#" 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setExpandedDescriptions(prev => ({
                                                ...prev,
                                                [product.vap_id]: false
                                            }));
                                        }}
                                        className="text-primary ms-1"
                                    >
                                        less
                                    </a>
                                )}
                            </td>
                            <td>
                                {product.vap_image_url && (
                                    <img 
                                        src={process.env.REACT_APP_IMAGE_URL + '/' + product.vap_image_url} 
                                        alt={product.vap_name} 
                                        style={{ width: '100px', height: '100px', objectFit: 'contain', borderRadius: '5px' }} 
                                    />
                                ) || <span className='table-warning'>No Image</span>}
                            </td>
                            <td>R{parseFloat(product.vap_cost).toFixed(2)}</td>
                            <td>R{parseFloat(product.vap_sell).toFixed(2)}</td>
                            <td>{product.status}</td>
                            <td>
                                <button className="btn btn-warning btn-sm me-2" onClick={() => openModal(product)}>Edit</button>
                                
                                <button className="btn btn-danger btn-sm me-2" onClick={() => deleteValueAddedProduct(product.vap_id)}>Delete</button>
                                <button className="btn btn-info btn-sm me-2" onClick={() => copyValueAddedProduct(product)}>Copy</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Modal for Adding/Editing Value Added Product */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="valueAddedProductModalLabel" aria-hidden={!showModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="valueAddedProductModalLabel">{editingProductId ? 'Edit Value Added Product' : 'Add Value Added Product'}</h5>
                            
                        </div>
                        <form onSubmit={addOrUpdateProduct}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label>SKU*</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        value={productData.vap_sku} 
                                        onChange={(e) => setProductData({ ...productData, vap_sku: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Name*</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        value={productData.vap_name} 
                                        onChange={(e) => setProductData({ ...productData, vap_name: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Description*</label>
                                    <textarea 
                                        className="form-control" 
                                        value={productData.vap_desc} 
                                        onChange={(e) => setProductData({ ...productData, vap_desc: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Cost*</label>
                                    <input 
                                        type="number" 
                                        step="0.01" 
                                        className="form-control" 
                                        value={productData.vap_cost} 
                                        onChange={(e) => setProductData({ ...productData, vap_cost: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Sell Price*</label>
                                    <input 
                                        type="number" 
                                        step="0.01" 
                                        className="form-control" 
                                        value={productData.vap_sell} 
                                        onChange={(e) => setProductData({ ...productData, vap_sell: e.target.value })}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Value Added Product Image (150x150)*</label>
                                    <div className="form-group">
                                        {initialImageUrl && (
                                            <img src={process.env.REACT_APP_IMAGE_URL + '/' + initialImageUrl} alt="Current Image" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />
                                        )}
                                    </div>
                                </div>
                                <div className="form-group">
                                    
                                    <ImageUpload 
                                        ref={imageUploadRef}
                                        onUploadSuccess={(url) => setProductData({ ...productData, vap_image_url: url })}
                                        identifier="value-added-product"
                                        key={imageUploadKey}
                                        category_id={4}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Status*</label>
                                    <select 
                                        className="form-control" 
                                        value={productData.status} 
                                        onChange={(e) => setProductData({ ...productData, status: e.target.value })}
                                        required
                                    >
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="deleted">Deleted</option>
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => resetForm()}>Close</button>
                                <button type="submit" className="btn btn-primary">
                                    {editingProductId ? 'Update Value Added Product' : 'Add Value Added Product'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ValueAddedProducts;
