import React from 'react';
import ProviderCategories from '../components/ProviderCategories';
import 'bootstrap/dist/css/bootstrap.min.css';

const ProviderCategoriesPage = () => {
    return (
        <div className="container">
            <ProviderCategories />
        </div>
    );
};

export default ProviderCategoriesPage;