import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PromotionalPackages = () => {
    const [promotionalPackages, setPromotionalPackages] = useState([]);
    const [packageData, setPackageData] = useState({
        package_id: '',
        promotion_category_id: '',
        package_name: '',
        sell_price_incl: '',
        installation: '',
        package_priority: '',
        active_from: '',
        active_until: '',
        status: 'active',
        package_description: ''
    });
    const [editingPackageId, setEditingPackageId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [providerCategories, setProviderCategories] = useState([]);
    const [promotionCategories, setPromotionCategories] = useState([]);
    const [packages, setPackages] = useState([]);
    const [selectedProviderCategory, setSelectedProviderCategory] = useState('');
    const [sortConfig, setSortConfig] = useState({
        key: 'promotional_package_id',
        direction: 'asc'
    });
    const [expandedDescriptions, setExpandedDescriptions] = useState({});

    useEffect(() => {
        fetchPromotionalPackages();
        fetchProviderCategories();
        fetchPromotionCategories();
        fetchPackages();
    }, []);

    useEffect(() => {
        const storedPackageData = localStorage.getItem('createPromotionalPackage');
        if (storedPackageData && packages.length > 0) {
            const data = JSON.parse(storedPackageData);
            
            // Set the provider category first
            setSelectedProviderCategory(data.prov_cat_id.toString());
            
            // Find the package details
            const selectedPackage = packages.find(pkg => pkg.package_id === data.package_id);
            if (selectedPackage) {
                setPackageData({
                    package_id: data.package_id.toString(),
                    promotion_category_id: '',
                    package_name: `${selectedPackage.package_name}`,
                    sell_price_incl: selectedPackage.sell_price_incl,
                    installation: selectedPackage.installation,
                    package_priority: '',
                    active_from: '',
                    active_until: '',
                    status: 'active',
                    package_description: selectedPackage.package_description || ''
                });
                
                if (data.showModal) {
                    setShowModal(true);
                }
            }
            
            localStorage.removeItem('createPromotionalPackage');
        }
    }, [packages]);


// Add this function near the top of the component
const formatDateToGMTPlus2 = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    date.setHours(date.getHours() + 2); // Adjust to GMT+2
    return date.toLocaleString('en-ZA', { 
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC'
    });
};



    const fetchPromotionalPackages = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotional-packages`);
            console.log('API Response:', response.data);
            const packagesWithProviderCategory = response.data.map(pkg => ({
                ...pkg,
                providerCategoryName: pkg.Package?.ProviderCategory?.prov_cat_name || 'N/A',
                base_package_name: pkg.base_package_name || 'N/A',
                package_name: pkg.package_name
            }));
            console.log('Processed Packages:', packagesWithProviderCategory);
            setPromotionalPackages(packagesWithProviderCategory);
        } catch (error) {
            console.error("Error fetching promotional packages:", error);
        }
    };

    const fetchProviderCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/provider-categories`);
            setProviderCategories(response.data);
        } catch (error) {
            console.error("Error fetching provider categories:", error);
        }
    };

    const fetchPromotionCategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/promotion-categories`);
            setPromotionCategories(response.data);
        } catch (error) {
            console.error("Error fetching promotion categories:", error);
        }
    };

    const fetchPackages = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/packages`);
            setPackages(response.data);
        } catch (error) {
            console.error("Error fetching packages:", error);
        }
    };

    const handleInputChange = (e) => {
        setPackageData({ ...packageData, [e.target.name]: e.target.value });
    };

    const addOrUpdatePackage = async (e) => {
        e.preventDefault();
        
        // Create a copy of packageData to modify dates
        const adjustedPackageData = {
            ...packageData
        };

      // Adjust active_from if it exists
      if (adjustedPackageData.active_from) {
        const fromDate = new Date(adjustedPackageData.active_from);
        fromDate.setHours(fromDate.getHours() );
        adjustedPackageData.active_from = fromDate.toISOString().split('.')[0];
    }

    // Adjust active_until if it exists
    if (adjustedPackageData.active_until) {
        const untilDate = new Date(adjustedPackageData.active_until);
        untilDate.setHours(untilDate.getHours() );
        adjustedPackageData.active_until = untilDate.toISOString().split('.')[0];
    }

        try {
            if (editingPackageId) {
                await axios.put(`${process.env.REACT_APP_API_URL}/promotional-packages/${editingPackageId}`, adjustedPackageData);
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/promotional-packages`, adjustedPackageData);
            }
            fetchPromotionalPackages();
            resetForm();
        } catch (error) {
            console.error("Error saving promotional package:", error);
        }
    };

    const startEditing = (promotionalPackage) => {
        const selectedPackage = packages.find(pkg => pkg.package_id.toString() === promotionalPackage.package_id.toString());
        setSelectedProviderCategory(selectedPackage ? selectedPackage.prov_cat_id.toString() : '');
        setPackageData({
            package_id: promotionalPackage.package_id.toString(),
            promotion_category_id: promotionalPackage.promotion_category_id.toString(),
            package_name: promotionalPackage.package_name,
            sell_price_incl: promotionalPackage.sell_price_incl,
            installation: promotionalPackage.installation,
            package_priority: promotionalPackage.package_priority,
            active_from: promotionalPackage.active_from ? (() => {
                const date = new Date(promotionalPackage.active_from);
                date.setHours(date.getHours() + 2); // Adjust to GMT+2
                return date.toISOString().split('T')[0];
            })() : '',
            active_until: promotionalPackage.active_until ? (() => {
                const date = new Date(promotionalPackage.active_until);
                date.setHours(date.getHours() + 2); // Adjust to GMT+2
                return date.toISOString().split('T')[0];
            })() : '',
            status: promotionalPackage.status,
            package_description: promotionalPackage.package_description || ''
        });
        setEditingPackageId(promotionalPackage.promotional_package_id);
        setShowModal(true);
    };

    const deletePackage = async (id) => {
        if (window.confirm("Are you sure you want to delete this promotional package?")) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/promotional-packages/${id}`);
                fetchPromotionalPackages();
            } catch (error) {
                console.error("Error deleting promotional package:", error);
            }
        }
    };

    const resetForm = () => {
        setPackageData({
            package_id: '',
            promotion_category_id: '',
            package_name: '',
            sell_price_incl: '',
            installation: '',
            package_priority: '',
            active_from: '',
            active_until: '',
            status: 'active',
            package_description: ''
        });
        setEditingPackageId(null);
        setShowModal(false);
    };

    const groupPackagesByPromotionCategory = () => {
        return promotionalPackages.reduce((groups, pkg) => {
            // Skip packages without PromotionCategory
            if (!pkg.PromotionCategory) return groups;

            const category = pkg.PromotionCategory.promotion_category_name;
            if (!groups[category]) {
                groups[category] = {
                    category,
                    packages: []
                };
            }
            groups[category].packages.push(pkg);
            return groups;
        }, {});
    };

    const handlePackageChange = (e) => {
        const selectedPackageId = e.target.value;
        const selectedPackage = packages.find(pkg => pkg.package_id.toString() === selectedPackageId);
        
        if (selectedPackage) {
            setPackageData(prevData => ({
                ...prevData,
                package_id: selectedPackageId,
                package_name: editingPackageId ? prevData.package_name : selectedPackage.package_name,
                sell_price_incl: selectedPackage.sell_price_incl,
                installation: selectedPackage.installation,
                package_description: editingPackageId ? prevData.package_description : selectedPackage.package_description || ''
            }));
        } else {
            // Reset values if no package is selected
            setPackageData(prevData => ({
                ...prevData,
                package_id: '',
                package_name: '',
                sell_price_incl: '',
                installation: '',
                package_description: ''
            }));
        }
    };

    const handleProviderCategoryChange = (e) => {
        const value = e.target.value;
        setSelectedProviderCategory(value);
        setPackageData(prev => ({
            ...prev,
            package_id: '',
            package_name: '',
            sell_price_incl: '',
            installation: ''
        }));
    };

    const setDefaultPackage = async (packageId) => {
        try {
            if (window.confirm("Set this as the default package? This will remove default status from any other package in this category.")) {
                await axios.put(`${process.env.REACT_APP_API_URL}/promotional-packages/${packageId}/set-default`);
                fetchPromotionalPackages(); // Refresh the list
            }
        } catch (error) {
            console.error("Error setting default package:", error);
            alert("An error occurred while setting the default package.");
        }
    };

    const sortPromotionalPackages = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const grouped = groupPackagesByPromotionCategory();
        const sortedGroupedPackages = {};

        Object.keys(grouped).forEach(categoryName => {
            if (!grouped[categoryName]) return;
            
            const categoryPackages = [...grouped[categoryName].packages];
            
            categoryPackages.sort((a, b) => {
                if (!a[key] || !b[key]) return 0;
                
                // Handle numeric values
                if (['promotional_package_id', 'sell_price_incl', 'installation', 'package_priority', 'download_speed_mb', 'upload_speed_mb', 'cost_price_incl'].includes(key)) {
                    return direction === 'asc' ? parseFloat(a[key]) - parseFloat(b[key]) : parseFloat(b[key]) - parseFloat(a[key]);
                }
                // Handle dates
                if (['active_from', 'active_until'].includes(key)) {
                    const dateA = new Date(a[key]);
                    const dateB = new Date(b[key]);
                    return direction === 'asc' ? dateA - dateB : dateB - dateA;
                }
                // Default string comparison
                if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
                if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
                return 0;
            });

            sortedGroupedPackages[categoryName] = {
                ...grouped[categoryName],
                packages: categoryPackages
            };
        });

        const sortedPackages = Object.values(sortedGroupedPackages)
            .flatMap(group => group.packages);

        setPromotionalPackages(sortedPackages);
    };

    const copyPackage = (promotionalPackage) => {
        const selectedPackage = packages.find(pkg => pkg.package_id === promotionalPackage.package_id);
        
        // Set provider category and handle package change
        setSelectedProviderCategory(selectedPackage ? selectedPackage.prov_cat_id.toString() : '');
        
        setPackageData({
            package_id: promotionalPackage.package_id.toString(),
            promotion_category_id: promotionalPackage.promotion_category_id.toString(),
            package_name: promotionalPackage.package_name,
            sell_price_incl: promotionalPackage.sell_price_incl,
            installation: promotionalPackage.installation,
            package_priority: promotionalPackage.package_priority,
            active_from: promotionalPackage.active_from ? (() => {
                const date = new Date(promotionalPackage.active_from);
                date.setHours(date.getHours() + 2); // Adjust to GMT+2
                return date.toISOString().split('T')[0];
            })() : '',
            active_until: promotionalPackage.active_until ? (() => {
                const date = new Date(promotionalPackage.active_until);
                date.setHours(date.getHours() + 2); // Adjust to GMT+2
                return date.toISOString().split('T')[0];
            })() : '',
            status: 'active',  // Default to active for new copy
            package_description: promotionalPackage.package_description || '',
         
        });
        
        setEditingPackageId(null);  // Ensure we're creating a new package
        setShowModal(true);
    };

    const truncateDescription = (text, id) => {
        if (!text) return '';
        if (expandedDescriptions[id]) return text;
        
        return text.length > 50 ? (
            <span>
                {text.substring(0, 50)}...
                <a 
                    href="#" 
                    onClick={(e) => {
                        e.preventDefault();
                        setExpandedDescriptions(prev => ({
                            ...prev,
                            [id]: true
                        }));
                    }}
                    className="text-primary ms-1"
                >
                    more
                </a>
            </span>
        ) : text;
    };

 



    return (
        <div className="container mt-4">
            <h1>Promotional Packages</h1>
            <button className="btn btn-primary mb-3" onClick={() => { resetForm(); setShowModal(true); }}>Add Promotional Package</button>
            {Object.values(groupPackagesByPromotionCategory()).map(group => (
                <div key={group.category} className="mb-4">
                    <h2>Promotion Category: {group.category}</h2>
                    <table className="table">
                        <thead>
                            <tr>
                                <th onClick={() => sortPromotionalPackages('promotional_package_id')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        ID {sortConfig.key === 'promotional_package_id' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th onClick={() => sortPromotionalPackages('package_name')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        Promo Name {sortConfig.key === 'package_name' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th onClick={() => sortPromotionalPackages('base_package_name')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        Base Package {sortConfig.key === 'base_package_name' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th>Description</th>
                                <th onClick={() => sortPromotionalPackages('prov_cat_name')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        Prov Cat {sortConfig.key === 'prov_cat_name' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th onClick={() => sortPromotionalPackages('term')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        Term {sortConfig.key === 'term' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th onClick={() => sortPromotionalPackages('download_speed_mb')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        DL {sortConfig.key === 'download_speed_mb' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th onClick={() => sortPromotionalPackages('upload_speed_mb')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        UL {sortConfig.key === 'upload_speed_mb' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th onClick={() => sortPromotionalPackages('cost_price_incl')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        Price {sortConfig.key === 'cost_price_incl' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th onClick={() => sortPromotionalPackages('sell_price_incl')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        Sale Price {sortConfig.key === 'sell_price_incl' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th onClick={() => sortPromotionalPackages('installation')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        Installation {sortConfig.key === 'installation' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th onClick={() => sortPromotionalPackages('package_priority')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        Priority {sortConfig.key === 'package_priority' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th onClick={() => sortPromotionalPackages('active_from')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        Active From {sortConfig.key === 'active_from' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th onClick={() => sortPromotionalPackages('active_until')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        Active Until {sortConfig.key === 'active_until' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th onClick={() => sortPromotionalPackages('status')} style={{cursor: 'pointer'}}>
                                    <div className="d-flex align-items-center">
                                        Status {sortConfig.key === 'status' && <span className="ms-1">{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
                                    </div>
                                </th>
                                <th>Default</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {group.packages.map(pkg => (
                                <tr key={pkg.promotional_package_id}>
                                    <td>{pkg.promotional_package_id}</td>
                                    <td>{pkg.package_name}</td>
                                    
                                    <td>{pkg.base_package_name}</td>
                                    <td>
                                        {truncateDescription(pkg.package_description, pkg.promotional_package_id)}
                                        {expandedDescriptions[pkg.promotional_package_id] && (
                                            <a 
                                                href="#" 
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setExpandedDescriptions(prev => ({
                                                        ...prev,
                                                        [pkg.promotional_package_id]: false
                                                    }));
                                                }}
                                                className="text-primary ms-1"
                                            >
                                                less
                                            </a>
                                        )}
                                    </td>
                                    <td>{pkg.prov_cat_name || 'N/A'}</td>
                                    
                                    <td>{pkg.term}</td>
                                    <td>{pkg.download_speed_mb} Mbps</td>
                                    <td>{pkg.upload_speed_mb} Mbps</td>
                                    <td>R{pkg.cost_price_incl}</td>
                                    <td>R{pkg.sell_price_incl}</td>
                                    <td>R{pkg.installation}</td>
                                    <td>{pkg.package_priority}</td>
                                    <td>{formatDateToGMTPlus2(pkg.active_from)}</td>
                                    <td>{formatDateToGMTPlus2(pkg.active_until)}</td>
                                    <td>{pkg.status}</td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <button 
                                                className={`btn btn-sm ${pkg.promo_package_default ? 'btn-success' : 'btn-outline-success'} me-2`}
                                                onClick={() => setDefaultPackage(pkg.promotional_package_id)}
                                                title={pkg.promo_package_default ? 'Default Package' : 'Set as Default'}
                                            >
                                                <i className="fas fa-star"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <button className="btn btn-warning btn-sm me-2" onClick={() => startEditing(pkg)}>Edit</button>   
                                            <button className="btn btn-danger btn-sm me-2" onClick={() => deletePackage(pkg.promotional_package_id)}>Delete</button>
                                            <button className="btn btn-info btn-sm me-2" onClick={() => copyPackage(pkg)}>Copy</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ))}

           

            {/* Modal for Adding/Editing Promotional Package */}
            <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="promotionalPackageModalLabel" aria-hidden={!showModal}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="promotionalPackageModalLabel">{editingPackageId ? 'Edit Promotional Package' : 'Add Promotional Package'}</h5>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={addOrUpdatePackage}>
                                <div className="form-group mb-3">
                                    <label>Provider Category*</label>
                                    <select className="form-control" name="provider_category" value={selectedProviderCategory} onChange={handleProviderCategoryChange} required>
                                        <option value="">Select Provider Category</option>
                                        {providerCategories.map(category => (
                                            <option key={category.prov_cat_id} value={category.prov_cat_id}>
                                                {category.prov_cat_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Package*</label>
                                    <select className="form-control" name="package_id" value={packageData.package_id} onChange={handlePackageChange} required>
                                        <option value="">Select Package</option>
                                        {packages
                                            .filter(pkg => pkg.prov_cat_id === parseInt(selectedProviderCategory))
                                            .map(pkg => (
                                                <option key={pkg.package_id} value={pkg.package_id}>
                                                    {pkg.package_name} - Term: {pkg.term}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Promotion Category*</label>
                                    <select className="form-control" name="promotion_category_id" value={packageData.promotion_category_id} onChange={handleInputChange} required>
                                        <option value="">Select Promotion Category</option>
                                        {promotionCategories.map(category => (
                                            <option key={category.promotion_category_id} value={category.promotion_category_id}>
                                                {category.promotion_category_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label>Package Name*</label>
                                    <input type="text" className="form-control" name="package_name" value={packageData.package_name} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group">
                                    <label>Package Description</label>
                                    <textarea 
                                        className="form-control" 
                                        name="package_description"
                                        value={packageData.package_description || ''} 
                                        onChange={handleInputChange}
                                        rows="3"
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Promotional Price (R)*</label>
                                    <input type="number" className="form-control" name="sell_price_incl" value={packageData.sell_price_incl} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Installation Price (R)</label>
                                    <input type="number" className="form-control" name="installation" value={packageData.installation} onChange={handleInputChange} />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Priority*</label>
                                    <input type="number" className="form-control" name="package_priority" value={packageData.package_priority} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Active From*</label>
                                    <input type="date" className="form-control" name="active_from" value={packageData.active_from} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Active Until*</label>
                                    <input type="date" className="form-control" name="active_until" value={packageData.active_until} onChange={handleInputChange} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label>Status*</label>
                                    <select className="form-control" name="status" value={packageData.status} onChange={handleInputChange} required>
                                        <option value="active">Active</option>
                                        <option value="disabled">Disabled</option>
                                        <option value="deleted">Deleted</option>
                                    </select>
                                </div>
                             
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={resetForm}>Close</button>
                                    <button type="submit" className="btn btn-primary">
                                        {editingPackageId ? 'Update Promotional Package' : 'Add Promotional Package'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PromotionalPackages;
